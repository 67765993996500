import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationModals } from "app/common/hooks";
import { usePaginatedCollector, useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import Table from "app/components/atoms/table";
import { SearchInput } from "app/components/atoms/input";
import { Select, SelectSearch } from "app/components/atoms/select";
import Typography from "app/components/atoms/typography";
import {
  IconButton,
  BadgeButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Badge } from "app/components/atoms/badge";
import { FormHeader } from "app/components/atoms/layout";

const breadcrumbItems = [
  { label: "Sales", href: "#" },
  { label: "Invoice", href: "#" },
];

function getTotal(orders) {
  return orders
    ?.map((order) => {
      return (
        order.OrderItems.map(
          (orderItem) =>
            orderItem.qty * +orderItem.unit_price +
            orderItem.variants
              .map((item) => +item.price)
              .reduce((partialSum, a) => partialSum + a, 0) *
              orderItem.qty
        ).reduce((partialSum, a) => partialSum + a, 0) +
        +order.delivery_fee -
        +order.delivery_fee_discount
      );
    })
    .reduce((partialSum, a) => partialSum + a, 0);
}

function convertPaymentStatus(status) {
  switch (status) {
    case 1:
      return "Unpaid";
    case 2:
      return "Partial";
    case 3:
      return "Lunas";
    case 4:
      return "Cancel";
    default:
      break;
  }
}

function InvoicePage() {
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();

  const [params, setParams] = useState({
    search: "",
    status: null,
    customer_id: null,
    page: 1,
    limit: 50,
  });

  useEffect(() => {
    collectInvoices(params);
  }, [params]);

  const [
    invoices,
    setInvoices,
    invoicesLoader,
    collectInvoices,
    invoicePaginationProps
  ] = usePaginatedCollector({ modelName: "invoice", params: params });

  const [customers, setCustomers, customersLoader, collectCustomers] =
    useCollector({ modelName: "customer" });

  return (
    <MainLayout
      activeSidebarNavigation="invoice"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Invoice"
      headingButtons={[
        {
          label: "Tambah Invoice",
          type: "routerButton",
          to: "/invoice/create",
        },
      ]}
    >
      <AppModals
        items={["invoice.delete"]}
        onSuccess={{
          "invoice.delete": () => collectInvoices(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <SelectSearch
                label="Customer"
                placeholder="Customer"
                options={customers
                  ?.sort(function (a, b) {
                    if (
                      a.first_name?.toLowerCase() < b.first_name?.toLowerCase()
                    )
                      return -1;
                    if (
                      a.first_name?.toLowerCase() > b.first_name?.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  .map((customer) => ({
                    value: customer.id,
                    label:
                      customer.first_name +
                      " " +
                      customer.middle_name +
                      " " +
                      customer.last_name +
                      (customer.nick_name
                        ? " (" + customer.nick_name + ")"
                        : ""),
                  }))}
                value={params.customer_id}
                onChange={(e) =>
                  setParams({ ...params, customer_id: e.target.value })
                }
              />
              {/* <SearchInput
                placeholder="Search Customer"
                value={params.search}
                onChange={(e) =>
                  setParams({ ...params, search: e.target.value })
                }
              /> */}
              <Select
                label="Status"
                placeholder="Status Invoice"
                value={params.status}
                onChange={(e) =>
                  setParams({ ...params, status: e.target.value })
                }
                options={[
                  {
                    value: 1,
                    label: "Unpaid",
                  },
                  {
                    value: 2,
                    label: "Partial",
                  },
                  {
                    value: 3,
                    label: "Lunas",
                  },
                ]}
              />
              {/* <Select
                label="Customer"
                placeholder="Customer"
                options={customers
                  ?.sort(function (a, b) {
                    if (
                      a.first_name?.toLowerCase() < b.first_name?.toLowerCase()
                    )
                      return -1;
                    if (
                      a.first_name?.toLowerCase() > b.first_name?.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  ?.map((customer) => ({
                    value: customer.id,
                    label:
                      customer.first_name +
                      " " +
                      customer.middle_name +
                      " " +
                      customer.last_name +
                      (customer.nick_name
                        ? " (" + customer.nick_name + ")"
                        : ""),
                  }))}
                value={params.customer_id}
                onChange={(e) =>
                  setParams({ ...params, customer_id: e.target.value })
                }
              /> */}
            </FormHeader>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>No.</Table.Heading>
              <Table.Heading>Customer</Table.Heading>
              {/* <Table.Heading>Jatuh Tempo</Table.Heading> */}
              <Table.Heading className="hidden lg:table-cell">
                <p className="text-center">Sales</p>
              </Table.Heading>
              <Table.Heading>
                <p className="text-right">Total</p>
              </Table.Heading>
              <Table.Heading>
                <p className="text-right">Payment</p>
              </Table.Heading>
              <Table.Heading>
                <p className="text-right">Kredit</p>
              </Table.Heading>
              <Table.Heading>
                <p className="text-right">Kurang / Lebih</p>
              </Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {invoices?.map((invoice, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>
                  <p>{invoice.inv_code}</p>
                  <p className="text-xs font-light text-gray-400">
                    <Typography.Date size="xs" date={invoice.createdAt} />
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    {invoice.Customer?.first_name}{" "}
                    {invoice.Customer?.middle_name}{" "}
                    {invoice.Customer?.last_name}
                  </p>
                  <p className="text-xs font-light text-gray-400">
                    {invoice.Customer?.nick_name}
                    {invoice.Customer?.deletedAt !== null ? (
                      <span className="text-danger-400 font-light text-xs">
                        {" "}
                        [deleted customer]
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                </Table.Cell>
                {/* <Table.Cell>
                  <Typography.Date date={invoice.due_date} />
                </Table.Cell> */}
                <Table.Cell className="hidden lg:table-cell">
                  <p className="text-center">
                    {invoice?.userCreateInvoice?.username}
                  </p>
                  {/* <p className="text-center text-danger-500 text-xs font-extralight">
                    (hardcoded)
                  </p> */}
                </Table.Cell>
                <Table.Cell>
                  <p className="text-right">
                    <Typography.Currency number={invoice.total_amount} />
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p className="text-right">
                    <Typography.Currency number={invoice.total_payment} />
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p className="text-right">
                    <Typography.Currency
                      number={invoice?.credit_amount}
                    ></Typography.Currency>
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p className="text-right">
                    <Typography.Currency
                      color={
                        Number(invoice.total_amount) -
                          Number(invoice.total_payment) -
                          Number(invoice.credit_amount) ===
                        0
                          ? ""
                          : Number(invoice.total_amount) -
                              Number(invoice.total_payment) -
                              Number(invoice.credit_amount) <
                            0
                          ? "green"
                          : "red"
                      }
                      number={Math.abs(
                        Number(invoice.total_amount) -
                          Number(invoice.total_payment) -
                          Number(invoice.credit_amount)
                      )}
                    />
                  </p>
                </Table.Cell>
                <Table.Cell>
                  {invoice.status === 3 ? (
                    <Badge color="green">
                      {convertPaymentStatus(invoice.status)}
                    </Badge>
                  ) : (
                    <></>
                  )}
                  {invoice.status === 2 ? (
                    <Badge color="yellow">
                      {convertPaymentStatus(invoice.status)}
                    </Badge>
                  ) : (
                    <></>
                  )}
                  {invoice.status === 1 ? (
                    <Badge color="red">
                      {convertPaymentStatus(invoice.status)}
                    </Badge>
                  ) : (
                    <></>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex">
                    <div className="flex space-x-2">
                      <BadgeButton
                        onClick={(e) =>
                          navigate("/invoice/detail/" + invoice.id)
                        }
                      >
                        View
                      </BadgeButton>
                    </div>
                    <IconButton
                      icon={TrashIcon}
                      onClick={(e) => {
                        setPayloads("invoice.delete", { id: invoice.id });
                        openModal("invoice.delete");
                      }}
                    />
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={invoicePaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default InvoicePage;
