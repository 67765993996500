import LoginPage from "app/pages/login";
import LogoutPage from "app/pages/logout";
import MainPage from "app/pages/main";
import CustomerPage from "app/pages/customer/index";
import CreditHistoryPage from "app/pages/creditHistory";
import SalesOrderPage from "app/pages/salesOrder";
import CreateSalesOrderPage from "app/pages/salesOrder/create";
import UpdateSalesOrderPage from "app/pages/salesOrder/update";
import InvoicePage from "app/pages/invoice";
import CreateInvoicePage from "app/pages/invoice/create";
import InvoiceDetailPage from "app/pages/invoice/detail";
import DailyOrderReportPage from "app/pages/report/dailyOrder";
import DailyMenuReportPage from "app/pages/report/dailyMenu";
import PaymentReportPage from "app/pages/report/payment";
import CreditRecapReportPage from "app/pages/report/creditRecap";
import CreditHistoryReportPage from "app/pages/report/creditHistory";
import MenuCategoryPage from "app/pages/menuCategory";
import DailyMenuPage from "app/pages/dailyMenu";
import VariantPage from "app/pages/variant";
import ShipmentCostCategoryPage from "app/pages/shipmentCostCategory";
import UserProfilePage from "app/pages/user";

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/",
    element: <SalesOrderPage />,
  },
  {
    path: "/customer/*",
    element: <CustomerPage />,
  },
  {
    path: "/credit_history",
    element: <CreditHistoryPage />,
  },
  {
    path: "/sales_order",
    element: <SalesOrderPage />,
  },
  {
    path: "/sales_order/create",
    element: <CreateSalesOrderPage />,
  },
  {
    path: "/sales_order/update/:id",
    element: <UpdateSalesOrderPage />,
  },
  {
    path: "/invoice",
    element: <InvoicePage />,
  },
  {
    path: "/invoice/create",
    element: <CreateInvoicePage />,
  },
  {
    path: "/invoice/detail/:id",
    element: <InvoiceDetailPage />,
  },
  {
    path: "/report/daily_order",
    element: <DailyOrderReportPage />,
  },
  {
    path: "/report/daily_menu",
    element: <DailyMenuReportPage />,
  },
  {
    path: "/report/credit_recap",
    element: <CreditRecapReportPage />,
  },
  {
    path: "/report/credit_history",
    element: <CreditHistoryReportPage />,
  },
  {
    path: "/report/payment",
    element: <PaymentReportPage />,
  },
  {
    path: "/menu_category",
    element: <MenuCategoryPage />,
  },
  {
    path: "/daily_menu",
    element: <DailyMenuPage />,
  },
  {
    path: "/variant",
    element: <VariantPage />,
  },
  {
    path: "/shipment_cost_category",
    element: <ShipmentCostCategoryPage />,
  },
  {
    path: "/profile",
    element: <UserProfilePage />,
  },
];

export default routes;
