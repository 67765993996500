import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import { ModalMolecule } from "app/components/molecules/modal";
import LogoSL from "img/sl-logo-fit.PNG";
import EristaLogo from "img/erista-logo.png";
import { DateTime } from "luxon";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

function ViewDailyMenuModal({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("dailyMenuReport.view");
  const data = payload?.data;
  const date = payload?.date;

  const handleClose = () => closeModal("dailyMenuReport.view");
  const handleSuccess = () => {
    closeModal("dailyMenuReport.view");
    onSuccess();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function mergeVariantsByName(variants) {
    const result = [];

    variants.forEach((variant) => {
      for (const key in variant) {
        const existingVariant = result.find((item) => item.menu === key);
        if (existingVariant) {
          existingVariant.qty += variant[key];
        } else {
          result.push({ menu: key, qty: variant[key] });
        }
      }
    });

    return result;
  }

  function mergeVariants(variants) {
    console.log(variants);
    var strkey = "";
    var keys = [];
    variants.forEach((variant) => {
      // console.log(variant);
      // console.log(Object.keys(variant).sort());
      // console.log(Object.keys(variant).sort().join(","));
      // console.log(Object.values(variant)[0]);
      strkey = Object.keys(variant).sort().join(",");
      var menu = {
        name: strkey,
        qty: Object.values(variant)[0],
      };

      // console.log(strkey);
      // if (keys.indexOf(strkey) < 0) {
      //   keys.push(strkey);
      // }
      if (keys.findIndex((x) => x.name === strkey) < 0) {
        keys.push(menu);
      } else {
        keys[keys.findIndex((x) => x.name === strkey)].qty +=
          Object.values(variant)[0];
      }

      console.log(
        keys[keys.findIndex((x) => x.name === strkey)],
        "+",
        Object.values(variant)[0]
      );

      strkey = "";
    });
    console.log(keys);

    return keys;
  }

  return (
    <ModalMolecule
      show={showStates["dailyMenuReport.view"]}
      title="View Daily Menu Report"
      onClose={handleClose}
      size="xl"
    >
      <div
        ref={componentRef}
        className="space-y-1 text-sm border border-gray-100 shadow-xl print:shadow-none print:border-none min-h-[800px]"
      >
        <div className="w-full bg-white flex justify-between px-16 py-8">
          <div>
            <h1 className="text-3xl font-bold text-black">Daily Menu Report</h1>
            <p className="text-sm font-medium text-yellow-500">
              {DateTime.fromISO(date).toFormat("cccc, dd-MM-yyyy")}
            </p>
          </div>
          <img alt="Logo Erista" src={EristaLogo} className="w-32"></img>
        </div>
        <div className="grid grid-cols-2 px-12 gap-4">
          <Table.Wrapper asIndex={false}>
            <Table.Header>
              <Table.HeaderRow>
                <Table.Heading>Menu A</Table.Heading>
                <Table.Heading align="right">Qty</Table.Heading>
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {data?.daily_order?.length > 0 && (
                <>
                  <Table.BodyRow key={0}>
                    <Table.Cell fontWeight="semibold">
                      <p className="pl-2">{data?.daily_order[0].name}</p>
                    </Table.Cell>
                    <Table.Cell fontWeight="semibold" align="right">
                      {data?.daily_order[0].total_order}
                    </Table.Cell>
                  </Table.BodyRow>
                  {mergeVariants(data?.daily_order[0].variants)?.length > 0 &&
                    mergeVariants(data?.daily_order[0].variants).map(
                      (list, index) => (
                        <Table.BodyRow key={index}>
                          <Table.Cell fontWeight="light" gradient="500">
                            <div className="pl-8" key={index}>
                              {list.name}
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            fontWeight="light"
                            gradient="500"
                            align="right"
                          >
                            {list.qty}
                          </Table.Cell>
                        </Table.BodyRow>
                      )
                    )}
                </>
              )}
            </Table.Body>
          </Table.Wrapper>
          <Table.Wrapper asIndex={false}>
            <Table.Header>
              <Table.HeaderRow>
                <Table.Heading>Menu B</Table.Heading>
                <Table.Heading align="right">Qty</Table.Heading>
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {data?.daily_order?.length > 1 && (
                <>
                  <Table.BodyRow key={1}>
                    <Table.Cell fontWeight="semibold">
                      <p className="pl-2">{data?.daily_order[1].name}</p>
                    </Table.Cell>
                    <Table.Cell fontWeight="semibold" align="right">
                      {data?.daily_order[1].total_order}
                    </Table.Cell>
                  </Table.BodyRow>
                  {mergeVariants(data?.daily_order[1].variants)?.length > 0 &&
                    mergeVariants(data?.daily_order[1].variants).map(
                      (list, index) => (
                        <Table.BodyRow key={index}>
                          <Table.Cell fontWeight="light" gradient="500">
                            <div className="pl-8" key={index}>
                              {list.name}
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            fontWeight="light"
                            gradient="500"
                            align="right"
                          >
                            {list.qty}
                          </Table.Cell>
                        </Table.BodyRow>
                      )
                    )}
                </>
              )}
            </Table.Body>
          </Table.Wrapper>
        </div>
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Report
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default ViewDailyMenuModal;
