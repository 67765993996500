import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { ModalMolecule } from "app/components/molecules/modal";
import { useFetcher } from "app/integration/common/hooks";
import LogoSL from "img/sl-logo-fit.PNG";
import EristaLogo from "img/erista-logo.png";
import { standardDate, standardDay } from "lib/str";
import { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { filter as _filter } from "underscore";

// const invoice = {
//   id: 97,
//   customer_id: 20,
//   branch_id: null,
//   total_amount: "157000.00",
//   is_use_credit: false,
//   is_use_plafon: false,
//   credit_amount: "0.00",
//   status: 3,
//   note: "",
//   inv_code: "INV000097",
//   due_date: "2023-06-29T09:37:36.307Z",
//   total_payment: "157000.00",
//   created_by: 1,
//   deleted_by: null,
//   createdAt: "2023-06-28T09:38:15.922Z",
//   updatedAt: "2023-06-28T09:38:39.362Z",
//   deletedAt: null,
//   Branch: null,
//   Customer: {
//     id: 20,
//     branch_id: null,
//     first_name: "Indarta",
//     last_name: "Wono Yuwono",
//     middle_name: "",
//     nick_name: "Tata Voila",
//     email: null,
//     phone_number: "+628113567966",
//     credit: "0.00",
//     plafon: "500000.00",
//     created_by: 1,
//     deleted_by: null,
//     createdAt: "2023-06-28T09:14:21.685Z",
//     updatedAt: "2023-06-28T09:38:39.374Z",
//     deletedAt: null,
//   },
//   Orders: [
//     {
//       id: 69,
//       invoice_id: 97,
//       customer_id: 20,
//       delivery_to: 21,
//       branch_id: null,
//       address_id: 22,
//       delivery_time: 0,
//       delivery_date: "2023-06-26T00:00:00.000Z",
//       note_order: "tolong titip di reception dan info kalo makanan",
//       order_code: "SOD000069",
//       delivery_fee: "10000.00",
//       delivery_fee_discount: "5000.00",
//       created_by: 1,
//       deleted_by: null,
//       total_order: "152000.00",
//       status: 1,
//       is_valid_order: true,
//       createdAt: "2023-06-28T09:18:01.078Z",
//       updatedAt: "2023-06-28T09:38:39.371Z",
//       deletedAt: null,
//       OrderItems: [
//         {
//           id: 116,
//           product_id: 57,
//           order_id: 69,
//           qty: 1,
//           unit_price: "64000.00",
//           notes: "ga mau sambel",
//           variants: [
//             {
//               variant_category_id: 85,
//               name: "Nasi Putih",
//               price: "0.00",
//             },
//             {
//               variant_category_id: 89,
//               name: "Extra Protein",
//               price: "10000.00",
//             },
//           ],
//           created_by: null,
//           deleted_by: null,
//           createdAt: "2023-06-28T09:18:01.081Z",
//           updatedAt: "2023-06-28T09:18:01.081Z",
//           deletedAt: null,
//           Product: {
//             id: 57,
//             product_category_id: 13,
//             name: "Nasi Campur Pontianak",
//             price: "64000.00",
//             notes: "",
//             delivery_date: "2023-06-26T00:00:00.000Z",
//             is_active: true,
//             m_variant_id: 33,
//             created_by: 1,
//             deleted_by: null,
//             createdAt: "2023-06-28T09:11:58.673Z",
//             updatedAt: "2023-06-28T09:11:58.673Z",
//             deletedAt: null,
//             ProductCategory: {
//               id: 13,
//               branch_id: null,
//               name: "B",
//               deleted_by: null,
//               created_by: 1,
//               createdAt: "2023-06-28T08:46:15.218Z",
//               updatedAt: "2023-06-28T08:46:15.218Z",
//               deletedAt: null,
//             },
//           },
//           VariantOrderItems: [],
//         },
//         {
//           id: 117,
//           product_id: 56,
//           order_id: 69,
//           qty: 1,
//           unit_price: "78000.00",
//           notes: "mau sambel",
//           variants: [
//             {
//               variant_category_id: 88,
//               name: "Less Protein",
//               price: "0.00",
//             },
//             {
//               variant_category_id: 90,
//               name: "Less Karbo",
//               price: "0.00",
//             },
//           ],
//           created_by: null,
//           deleted_by: null,
//           createdAt: "2023-06-28T09:18:01.083Z",
//           updatedAt: "2023-06-28T09:18:01.083Z",
//           deletedAt: null,
//           Product: {
//             id: 56,
//             product_category_id: 12,
//             name: "Tokyo Midnight Diner Ochazuke Salmon",
//             price: "78000.00",
//             notes: "",
//             delivery_date: "2023-06-26T00:00:00.000Z",
//             is_active: true,
//             m_variant_id: 33,
//             created_by: 1,
//             deleted_by: null,
//             createdAt: "2023-06-28T09:11:32.150Z",
//             updatedAt: "2023-06-28T09:11:32.150Z",
//             deletedAt: null,
//             ProductCategory: {
//               id: 12,
//               branch_id: null,
//               name: "A",
//               deleted_by: null,
//               created_by: 1,
//               createdAt: "2023-06-28T08:46:11.977Z",
//               updatedAt: "2023-06-28T08:46:11.977Z",
//               deletedAt: null,
//             },
//           },
//           VariantOrderItems: [],
//         },
//       ],
//     },
//   ],
//   Payments: [
//     {
//       id: 44,
//       invoice_id: 97,
//       method: 1,
//       payment_date: "2023-06-28",
//       payment_time: "16:38",
//       ref_number: "",
//       bank_name: "",
//       account_number: "",
//       total_payment: "157000.00",
//       created_by: 1,
//       updated_by: null,
//       deleted_by: null,
//       createdAt: "2023-06-28T09:38:39.353Z",
//       updatedAt: "2023-06-28T09:38:39.353Z",
//       deletedAt: null,
//     },
//   ],
//   userCreateInvoice: {
//     username: "superuser",
//   },
// };

function ViewInvoiceModal({ onSuccess = () => {} }) {
  const [queryParams, setQueryParams] = useState({
    // delivery_slip: 1,
    order_ids: "",
  });

  const [report] = useFetcher({
    modelName: "invoice",
    payload: { id: queryParams },
  });

  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("invoice.view");
  if (payload?.data?.length > 0) {
    console.log("payload: ", payload);
    console.log("data: ", payload.data);
  }
  const invoice = payload?.data;

  const localHelpers = {
    subtotal: (customerId) => {
      return _filter(invoice?.orders, {
        customer_id: customerId,
        invoice_id: invoice?.id,
      })
        .map((salesOrder) => {
          return salesOrder?.OrderItems.map(
            (orderDetail) =>
              orderDetail.qty * orderDetail.unit_price +
              orderDetail.variants
                .map((variant) => +variant.price)
                .reduce((partialSum, a) => partialSum + a, 0) *
                orderDetail.qty
          ).reduce((partialSum, a) => partialSum + a, 0);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCost: (customerId) => {
      return _filter(invoice?.orders, {
        customer_id: customerId,
        invoice_id: invoice?.id,
      })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCostDiscount: (customerId) => {
      return _filter(invoice?.orders, {
        customer_id: customerId,
        invoice_id: invoice?.id,
      })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee_discount);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    total: (customerId) => {
      return (
        +localHelpers.subtotal(customerId) +
        +localHelpers.shipmentCost(customerId) -
        +localHelpers.shipmentCostDiscount(customerId)
      );
    },
    payment: (payments) => {
      var total = 0;
      payments?.map((payment, key) => (total += Number(payment.total_payment)));
      return total;
    },
  };

  const handleClose = () => closeModal("invoice.view");
  const handleSuccess = () => {
    closeModal("invoice.view");
    onSuccess();
  };

  useEffect(() => {
    if (payload?.data?.length > 0) {
      console.log("payload: ", payload.data);
      const order_ids = payload.data.map((item) => {
        return item.order_id;
      });

      setQueryParams({
        ...queryParams,
        order_ids: order_ids.join(","),
      });
    } else {
      console.log("payload kosong");
    }
  }, [payload]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <ModalMolecule
      show={showStates["invoice.view"]}
      title="View Invoice"
      onClose={handleClose}
      size="xl"
    >
      <div
        ref={componentRef}
        className="space-y-1 text-sm border border-gray-100 shadow-xl print:shadow-none print:border-none"
      >
        <div>
          <div className="w-full bg-white flex justify-between px-8 py-4">
            <div>
              <h1 className="text-4xl font-bold text-black">Invoice</h1>
              <p className="text-sm font-medium text-yellow-500">
                {invoice?.inv_code}
              </p>
            </div>
            <img alt="Logo Erista" src={EristaLogo} className="w-24"></img>
          </div>
          <div className="px-8 py-4 flex justify-between">
            <div>
              <p className="text-lg font-bold">{invoice?.customerName}</p>
              <p className="text-sm font-medium text-gray-500">
                {invoice?.customerPhone}
              </p>
            </div>
            <div className="">
              <Typography.Date date={invoice?.invoiceDate}></Typography.Date>
            </div>
          </div>
        </div>
        <div className="px-8 py-4">
          <Table.Wrapper asIndex={false}>
            <Table.Header>
              <Table.HeaderRow>
                <Table.Heading>Order</Table.Heading>
                <Table.Heading className="text-right">Qty</Table.Heading>
                <Table.Heading className="text-right">
                  Harga Satuan
                </Table.Heading>
                <Table.Heading className="text-right">Subtotal</Table.Heading>
                {/* <Table.Heading className="text-right">Ongkir</Table.Heading> */}
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {/* {_filter(salesOrders, {
                            customer_id: values.customerId,
                          })?.map((salesOrder, key) => ( */}
              {invoice?.orders?.map((salesOrder, key) => (
                <Fragment key={key}>
                  <Table.BodyRow>
                    <Table.Cell size="sm" fontWeight="semibold">
                      {salesOrder.order_code}
                    </Table.Cell>
                    <Table.Cell size="sm"></Table.Cell>
                    <Table.Cell size="sm"></Table.Cell>
                    <Table.Cell size="sm"></Table.Cell>
                    {/* <Table.Cell size="sm" background={100} className="text-right">
                    <Typography.Currency number={salesOrder.delivery_fee} />
                    {" - "}
                    <Typography.Currency
                      number={salesOrder.delivery_fee_discount}
                    />
                  </Table.Cell> */}
                  </Table.BodyRow>
                  {salesOrder.OrderItems.map((orderItem, nestedKey) => (
                    <Fragment key={nestedKey}>
                      <Table.BodyRow>
                        <Table.Cell size="sm">
                          <span className="ml-5">
                            {orderItem.Product.ProductCategory.name}
                            {": "}
                            {orderItem.Product.name}
                          </span>
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                          {orderItem.qty}
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                          <Typography.Currency number={orderItem.unit_price} />
                        </Table.Cell>
                        <Table.Cell size="sm" className="text-right">
                          <Typography.Currency
                            number={+orderItem.qty * +orderItem.unit_price}
                          />
                        </Table.Cell>
                      </Table.BodyRow>
                      {orderItem.variants.map((item, nestedNestedKey) => (
                        <Table.BodyRow key={nestedNestedKey}>
                          <Table.Cell size="sm" className="pl-11">
                            {item.name}
                          </Table.Cell>
                          <Table.Cell size="sm" className="text-right">
                            {orderItem.qty}
                          </Table.Cell>
                          <Table.Cell size="sm" className="text-right">
                            <Typography.Currency number={item.price} />
                          </Table.Cell>
                          <Table.Cell className="text-right">
                            <Typography.Currency
                              number={item.price * orderItem.qty}
                            />
                          </Table.Cell>
                        </Table.BodyRow>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Table.Body>
          </Table.Wrapper>
        </div>
        <hr />
        <div className="w-full flex justify-between items-end px-10 py-4">
          <div className="w-1/2 text-gray-700">
            <h3 className="uppercase text-[10px] tracking-tight leading-tight font-medium ">
              Keterangan Pembayaran
            </h3>
            <p className="text-[10px] tracking-tight leading-tight font-extralight mt-2">
              Untuk melakukan pembayaran, harap transfer ke:
            </p>
            <div className="grid grid-cols-2 text-[10px] tracking-tight leading-tight w-fit gap-y-1 mt-1 pl-2">
              <span className="">Bank:</span>
              <span>BCA</span>
              <span className="">Nama:</span>
              <span>PT. Erista Dwatiga Teknologi</span>
              <span className="">Rekening:</span>
              <span>8292822323</span>
            </div>
            <p className="text-[10px] tracking-tight leading-tight font-extralight mt-2">
              Setelah melakukan pembayaran, harap mengirimkan bukti pembayaran
              melalui WhatsApp:
              <ul className="pl-2 space-y-1 mt-1">
                <li>
                  <strong>+62 8777-210-2323</strong> (Admin 1)
                </li>
                <li>
                  <strong>+62 8777-583-2323</strong> (Admin 2)
                </li>
              </ul>
              .
            </p>
          </div>
          <div className="h-full w-fit grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
            <div className="text-xs uppercase text-gray-600 text-right font-light">
              Subtotal:{" "}
            </div>
            <Typography.Currency
              number={localHelpers.subtotal(invoice?.customerId)}
            />
            <div className="text-xs uppercase text-gray-600 text-right font-light">
              Ongkir:{" "}
            </div>
            <Typography.Currency
              number={localHelpers.shipmentCost(invoice?.customerId)}
            />
            <div className="text-xs uppercase text-gray-600 text-right font-light">
              Diskon Ongkir:{" "}
            </div>
            <Typography.Currency
              number={-localHelpers.shipmentCostDiscount(invoice?.customerId)}
            />
            {/* <div className="col-span-2 my-3"></div> */}

            <div className="text-xs uppercase text-gray-600 text-right font-light">
              Total:{" "}
            </div>
            <Typography.Currency
              number={localHelpers.total(invoice?.customerId)}
            />
            <div className="col-span-2">
              <hr />
            </div>

            {invoice?.isUseCredit ? (
              <>
                <div className="text-xs uppercase text-right font-light text-primary-600">
                  Kredit Customer:{" "}
                </div>
                <div className="text-primary-600">
                  <Typography.Currency number={invoice?.creditAmount} />
                </div>
              </>
            ) : (
              <></>
            )}

            {invoice?.Payments?.map((payment, key) => (
              <>
                <div
                  className="text-xs uppercase text-primary-600 text-right font-light"
                  key={key}
                >
                  Pembayaran:{" "}
                </div>
                <Typography.Currency number={payment.total_payment} />
              </>
            ))}
            <div className="text-sm uppercase text-gray-600 text-right font-bold">
              Sisa Tagihan:{" "}
            </div>
            <Typography.Currency
              number={
                localHelpers.total(invoice?.customerId) -
                +invoice?.creditAmount -
                localHelpers.payment(invoice?.Payments)
              }
            />
          </div>
        </div>
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Invoice
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default ViewInvoiceModal;
