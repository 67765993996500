import clsx from "clsx";
import str from "lib/str";

function textSize(size) {
  return {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
    xl: "text-xl",
  }[size];
}

function Date({ date, format, size = "md" }) {
  return (
    <span
      className={clsx(
        "font-light text-gray-500 whitespace-nowrap text-sm",
        textSize(size)
      )}
    >
      {str.standardDate(date, "id", format)}
    </span>
  );
}

export default Date;
