import { useApplicationModals } from "app/common/hooks";
import { useCreateForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { ModalMolecule } from "app/components/molecules/modal";
import { useCollector } from "app/integration/common/hooks";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { SelectMolecule } from "app/components/molecules/select";
import { InputLabel } from "app/components/atoms/form";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import { ClosableCard } from "app/components/atoms/card";
import AppModals from ".";

function CreateCustomerModal({ onSuccess = () => {} }) {
  const { showStates, closeModal } = useApplicationModals();

  const handleClose = () => closeModal("customer.create");
  const handleSuccess = () => {
    closeModal("customer.create");
    onSuccess();
  };

  var isError = false;

  const { openModal, setPayloads } = useApplicationModals();

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      id: null,
      firstName: "",
      middleName: "",
      lastName: "",
      nickName: "",
      credit: 0,
      plafon: 0,
      addresses: [],
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Nama Customer harus diisi"),
      middleName: Yup.string(),
      lastName: Yup.string(),
      nickName: Yup.string(),
      credit: Yup.number(),
      plafon: Yup.number(),
      addresses: Yup.array().of(
        Yup.object().shape({
          address: Yup.string().required("Alamat harus diisi"),
          state: Yup.string(),
          city: Yup.string(),
          district: Yup.string(),
          subDistrict: Yup.string(),
          shipmentCostCategoryId: Yup.number().required(
            "Kategori Ongkir harus diisi"
          ),
        })
      ),
    }),
    onSubmit: async (values) => {
      await api.customer
        .store(
          values.firstName,
          values.lastName,
          values.middleName,
          values.nickName,
          values.email,
          values.phoneNumber,
          values.credit,
          values.plafon,
          values.addresses.map((address) => ({
            address: address.address,
            state: address.state,
            city: address.city,
            district: address.district,
            sub_district: address.subDistrict,
            delivery_category_id: address.shipmentCostCategoryId,
          }))
        )
        .catch((error) => {
          // console.log("eror: ", error);
          setPayloads("errorMessage.view", {
            title: "Tambah Customer Error",
            message:
              error?.response.status === 400
                ? "Gagal tambah customer karena nomor HP yang sama telah terdaftar pada database customer."
                : error?.response?.data?.message,
          });
          isError = true;
          openModal("errorMessage.view");
          // console.log("delete error: ", error?.response?.data?.message);
        });
    },
    onSuccess: () => {
      if (!isError) {
        handleSuccess();
      }
    },
  });

  const [shipmentCostCategories] = useCollector({
    modelName: "shipmentCostCategory",
  });

  return (
    <ModalMolecule
      show={showStates["customer.create"]}
      title="Tambah Customer"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Nama Depan"
                type="text"
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                errorMessage={
                  errors.firstName && touched.firstName && errors.firstName
                }
              />
              <InputMolecule
                label="Nama Tengah"
                type="text"
                name="middleName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.middleName}
                errorMessage={
                  errors.middleName && touched.middleName && errors.middleName
                }
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Nama Belakang"
                type="text"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                errorMessage={
                  errors.lastName && touched.lastName && errors.lastName
                }
              />
              <InputMolecule
                label="Nama Panggilan"
                type="text"
                name="nickName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nickName}
                errorMessage={
                  errors.nickName && touched.nickName && errors.nickName
                }
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Email"
                type="text"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                errorMessage={errors.email && touched.email && errors.email}
              />
              <InputMolecule
                label="No. HP"
                type="text"
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                errorMessage={
                  errors.phoneNumber &&
                  touched.phoneNumber &&
                  errors.phoneNumber
                }
              />
            </div>

            {/* <InputMolecule
              label="Kredit"
              type="number"
              name="credit"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.credit}
              errorMessage={errors.credit && touched.credit && errors.credit}
            />
            <InputMolecule
              label="Plafon"
              type="number"
              name="plafon"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.plafon}
              errorMessage={errors.plafon && touched.plafon && errors.plafon}
            /> */}
            <div>
              <hr className="my-5" />
            </div>
            <div>
              <InputLabel>Alamat</InputLabel>
              <FieldArray name="addresses">
                {(arrayHelpers) => (
                  <div className="space-y-2">
                    {values.addresses.map((address, addressKey) => (
                      <ClosableCard
                        key={addressKey}
                        onClose={() => arrayHelpers.remove(addressKey)}
                      >
                        <div className="space-y-2 p-2">
                          <InputMolecule
                            label="Alamat Lengkap"
                            type="text"
                            name={`addresses[${addressKey}].address`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={address.address}
                            errorMessage={
                              getIn(
                                errors,
                                `addresses[${addressKey}].address`
                              ) &&
                              getIn(
                                touched,
                                `addresses[${addressKey}].address`
                              ) &&
                              getIn(errors, `addresses[${addressKey}].address`)
                            }
                          />
                          {/* <InputMolecule
                                  label="Negara"
                                  type="text"
                                  name={`addresses[${addressKey}].state`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={address.state}
                                  errorMessage={
                                    getIn(errors, `addresses[${addressKey}].state`) && 
                                    getIn(touched, `addresses[${addressKey}].state`) &&
                                    getIn(errors, `addresses[${addressKey}].state`)
                                  }
                                /> */}
                          <InputMolecule
                            label="Kota"
                            type="text"
                            name={`addresses[${addressKey}].city`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={address.city}
                            errorMessage={
                              getIn(errors, `addresses[${addressKey}].city`) &&
                              getIn(touched, `addresses[${addressKey}].city`) &&
                              getIn(errors, `addresses[${addressKey}].city`)
                            }
                          />
                          {/* <InputMolecule
                                  label="Kecamatan"
                                  type="text"
                                  name={`addresses[${addressKey}].district`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={address.district}
                                  errorMessage={
                                    getIn(errors, `addresses[${addressKey}].district`) && 
                                    getIn(touched, `addresses[${addressKey}].district`) &&
                                    getIn(errors, `addresses[${addressKey}].district`)
                                  }
                                /> */}
                          {/* <InputMolecule
                                  label="Kelurahan"
                                  type="text"
                                  name={`addresses[${addressKey}].subDistrict`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={address.subDistrict}
                                  errorMessage={
                                    getIn(errors, `addresses[${addressKey}].subDistrict`) && 
                                    getIn(touched, `addresses[${addressKey}].subDistrict`) &&
                                    getIn(errors, `addresses[${addressKey}].subDistrict`)
                                  }
                                /> */}
                          <SelectMolecule
                            label="Kategori Ongkir"
                            name={`addresses[${addressKey}].shipmentCostCategoryId`}
                            options={shipmentCostCategories?.map(
                              (shipmentCostCategory) => ({
                                value: shipmentCostCategory.id,
                                label: shipmentCostCategory.name,
                              })
                            )}
                            absolute={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={address.shipmentCostCategoryId}
                            errorMessage={
                              getIn(
                                errors,
                                `addresses[${addressKey}].shipmentCostCategoryId`
                              ) &&
                              getIn(
                                touched,
                                `addresses[${addressKey}].shipmentCostCategoryId`
                              ) &&
                              getIn(
                                errors,
                                `addresses[${addressKey}].shipmentCostCategoryId`
                              )
                            }
                          />
                        </div>
                      </ClosableCard>
                    ))}
                    <div>
                      <div className="my-8">
                        <DottedOutlineButton
                          type="button"
                          onClick={(e) =>
                            arrayHelpers.push({
                              address: "",
                              state: "",
                              city: "",
                              district: "",
                              subDistrict: "",
                              shipmentCostCategoryId: null,
                            })
                          }
                        >
                          Tambah Alamat
                        </DottedOutlineButton>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
            <div>
              <hr className="my-5" />
            </div>
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Tambah Customer</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateCustomerModal;
