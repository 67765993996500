import { useApplicationModals } from "app/common/hooks";
import {
  BadgeButton,
  Button,
  DottedOutlineButton,
} from "app/components/atoms/button";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { SelectSearchMolecule } from "app/components/molecules/select";
import { ModalMolecule } from "app/components/molecules/modal";
import api from "app/integration/api";
import { useCollector, useCreateForm } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik } from "formik";
import { DateTime } from "luxon";
import { Fragment, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import style from "style/height.module.css";
import * as Yup from "yup";
import {
  filter as _filter,
  findWhere as _findWhere,
  flatten as _flatten,
  where as _where,
} from "underscore";
import { Badge } from "app/components/atoms/badge";

const breadcrumbItems = [
  { label: "Sales", href: "#" },
  { label: "Invoice", href: "/invoice" },
  { label: "Tambah Invoice", href: "#" },
];

function CreateInvoicePage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  // console.log(Object.fromEntries([...params]).customer_id);

  const handleSuccess = (res) => {
    navigate("/invoice/detail/" + res.data.id);
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const tomorrow = now.plus({ days: 1 });

  const [currentErrors, setCurrentErrors] = useState([]);

  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(+Object.fromEntries([...params])?.customer_id);
  const [invoices, setInvoices, invoicesLoader, collectInvoices] = useCollector(
    { modelName: "invoice", collectUponInstantiation: false }
  );
  const [customers] = useCollector({ modelName: "customer" });
  const [salesOrders, setSalesOrders, salesOrdersLoader, collectSalesOrders] = useCollector({ modelName: "salesOrder", collectUponInstantiation: false });

  useEffect(() => {
    if ( ! isNaN(selectedCustomerId)) {
      collectInvoices({ customer_id: selectedCustomerId });
      collectSalesOrders({ customer_id: selectedCustomerId });
    }
  }, [selectedCustomerId])

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      customerId: +Object.fromEntries([...params])?.customer_id,
      totalAmount: 0,
      isUseCredit: false,
      isUsePlafon: false,
      invoiceDate: dt,
      dueDate: tomorrow,
      creditAmount: 0,
      status: 0,
      note: "",
      orderIds: [],
    },
    validationSchema: Yup.object().shape({
      customerId: Yup.number()
        .typeError("Customer harus diisi")
        .required("Customer harus diisi"),
      totalAmount: Yup.number(),
      invoiceDate: Yup.date().required("Tanggal Invoice harus diisi"),
      dueDate: Yup.date().required("Tanggal Jatuh Tempo harus diisi"),
      creditAmount: Yup.number(),
      orderIds: Yup.array()
        .of(Yup.number())
        .min(1, "Customer ini tidak memiliki order"),
    }),
    onSubmit: async (values) => {
      let response = await api.invoice.store(
        values.customerId,
        values.totalAmount,
        values.isUseCredit,
        values.isUsePlafon,
        values.invoiceDate,
        values.dueDate,
        values.creditAmount,
        values.status,
        values.note,
        values.orderIds
      );
      return response;
    },
    onSuccess: handleSuccess,
  });

  const { openModal, setPayloads } = useApplicationModals();

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        console.log("values ketika error: ", values);

        // setPayloads("errorMessage.view", {
        //   title: "Tambah Invoice Error",
        //   data: err.errors,
        // });
        // openModal("errorMessage.view");
      });
  }

  const localHelpers = {
    subtotal: (customerId) => {
      return _filter(salesOrders, { customer_id: customerId, Invoice: null })
        .map((salesOrder) => {
          return salesOrder?.OrderItems.map(
            (orderDetail) =>
              orderDetail.qty * orderDetail.unit_price +
              orderDetail.variants
                .map((variant) => +variant.price)
                .reduce((partialSum, a) => partialSum + a, 0) *
                orderDetail.qty
          ).reduce((partialSum, a) => partialSum + a, 0);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCost: (customerId) => {
      return _filter(salesOrders, { customer_id: customerId, Invoice: null })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCostDiscount: (customerId) => {
      return _filter(salesOrders, { customer_id: customerId, Invoice: null })
        .map((salesOrder) => {
          return +salesOrder.delivery_fee_discount;
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    total: (customerId) => {
      return (
        +localHelpers.subtotal(customerId) +
        +localHelpers.shipmentCost(customerId) -
        +localHelpers.shipmentCostDiscount(customerId)
      );
    },
  };

  function customerInvoices(customerId) {
    // console.log(_where(invoices, { customer_id: customerId }));
    return _where(invoices, { customer_id: customerId });
  }

  return (
    <MainLayout
      activeSidebarNavigation="invoice"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Tambah Invoice"
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={false}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <FormHeader>
              <SelectSearchMolecule
                label="Customer"
                name="customerId"
                options={customers
                  ?.sort(function (a, b) {
                    if (
                      a.first_name?.toLowerCase() < b.first_name?.toLowerCase()
                    )
                      return -1;
                    if (
                      a.first_name?.toLowerCase() > b.first_name?.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  ?.map((customer) => ({
                    value: customer.id,
                    label:
                      customer.first_name +
                      " " +
                      customer.middle_name +
                      " " +
                      customer.last_name +
                      (customer.nick_name
                        ? " (" + customer.nick_name + ")"
                        : ""),
                  }))}
                onChange={(e) => {
                  handleChange(e);
                  const customerId = e.target.value;
                  setFieldValue(
                    "orderIds",
                    _filter(salesOrders, {
                      customer_id: customerId,
                      Invoice: null,
                    }).map((salesOrder) => salesOrder.id)
                  );
                  setSelectedCustomerId(customerId);
                }}
                onBlur={handleBlur}
                value={values.customerId}
                errorMessage={
                  errors.customerId && touched.customerId && errors.customerId
                }
              />
              <InputMolecule
                label="Tanggal Invoice"
                type="date"
                name="invoiceDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invoiceDate}
                errorMessage={
                  errors.invoiceDate &&
                  touched.invoiceDate &&
                  errors.invoiceDate
                }
              />
              {/* <InputMolecule
                label="Tanggal Jatuh Tempo"
                type="date"
                name="dueDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dueDate}
                errorMessage={
                  errors.dueDate && touched.dueDate && errors.dueDate
                }
              /> */}
              {/* <InputMolecule
                label="Catatan"
                type="text"
                name="note"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.note}
                errorMessage={errors.note && touched.note && errors.note}
              /> */}
            </FormHeader>
            <div className="py-2 overflow-y-visible col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="w-full">
                <FieldArray name="orderDetails">
                  {(arrayHelpers) => (
                    <div className="table-content-height overflow-y-auto">
                      <Table.Wrapper asIndex={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Order</Table.Heading>
                            <Table.Heading>Qty</Table.Heading>
                            <Table.Heading>Harga Satuan</Table.Heading>
                            <Table.Heading>Subtotal</Table.Heading>
                            <Table.Heading className="text-right">
                              Ongkir
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Diskon Ongkir
                            </Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {_filter(salesOrders, {
                            customer_id: values.customerId,
                            Invoice: null,
                          })?.map((salesOrder, key) => (
                            <Fragment key={key}>
                              <Table.BodyRow>
                                <Table.Cell size="sm" fontWeight="semibold">
                                  <div className="flex gap-4 items-center">
                                    {salesOrder.order_code}
                                    <Typography.Date
                                      date={salesOrder.delivery_date}
                                      size="sm"
                                    />
                                    {DateTime.fromISO(salesOrder.delivery_date)
                                      .set({
                                        hour: 0,
                                        minute: 0,
                                        second: 0,
                                        millisecond: 0,
                                      })
                                      .diff(
                                        DateTime.now().set({
                                          hour: 0,
                                          minute: 0,
                                          second: 0,
                                          millisecond: 0,
                                        }),
                                        ["days"]
                                      )
                                      .toObject().days < 0 &&
                                      !salesOrder.is_valid_order && (
                                        <Badge color="red">Expired</Badge>
                                      )}
                                  </div>
                                </Table.Cell>
                                <Table.Cell size="sm"></Table.Cell>
                                <Table.Cell size="sm"></Table.Cell>
                                <Table.Cell size="sm"></Table.Cell>
                                <Table.Cell size="sm" className="text-right">
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee}
                                  />
                                </Table.Cell>
                                <Table.Cell size="sm" className="text-right">
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee_discount}
                                  />
                                </Table.Cell>
                              </Table.BodyRow>
                              {salesOrder?.OrderItems?.map(
                                (orderItem, nestedKey) => (
                                  <Fragment key={nestedKey}>
                                    <Table.BodyRow>
                                      <Table.Cell size="sm">
                                        <span className="ml-5">
                                          {
                                            orderItem?.Product?.ProductCategory
                                              ?.name
                                          }
                                          {": "}
                                          {orderItem?.Product?.name}
                                        </span>
                                      </Table.Cell>
                                      <Table.Cell size="sm">
                                        {orderItem.qty}
                                      </Table.Cell>
                                      <Table.Cell size="sm">
                                        <Typography.Currency
                                          number={orderItem.unit_price}
                                        />
                                      </Table.Cell>
                                      <Table.Cell size="sm">
                                        <Typography.Currency
                                          number={
                                            +orderItem.qty *
                                            +orderItem.unit_price
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.BodyRow>
                                    {orderItem.variants.map(
                                      (item, nestedNestedKey) => (
                                        <Fragment key={nestedNestedKey}>
                                          <Table.BodyRow>
                                            <Table.Cell size="sm">
                                              <span className="ml-10">
                                                Varian{": "}
                                                {item.name}
                                              </span>
                                            </Table.Cell>
                                            <Table.Cell size="sm"></Table.Cell>
                                            <Table.Cell size="sm">
                                              <Typography.Currency
                                                number={item.price}
                                              />
                                            </Table.Cell>
                                            <Table.Cell size="sm">
                                              <Typography.Currency
                                                number={
                                                  item.price * orderItem.qty
                                                }
                                              />
                                            </Table.Cell>
                                          </Table.BodyRow>
                                        </Fragment>
                                      )
                                    )}
                                  </Fragment>
                                )
                              )}
                            </Fragment>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>
                    </div>
                  )}
                </FieldArray>
                <FormSummary>
                  <div className="h-full">
                    {values.customerId && (
                      <BadgeButton onClick={(e) => setShowInvoice(true)}>
                        Lihat Invoice Outstanding{" ("}
                        {
                          customerInvoices(values.customerId)?.filter(
                            (invoice) => invoice.status !== 3
                          ).length
                        }
                        {")"}
                      </BadgeButton>
                    )}
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.subtotal(values.customerId)}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.shipmentCost(values.customerId)}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Diskon Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.shipmentCostDiscount(
                        values.customerId
                      )}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Total:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.total(values.customerId)}
                    />
                    {/* <div className="col-span-2 my-3"></div> */}
                    <div className="flex justify-between">
                      <input
                        type="checkbox"
                        name="isUseCredit"
                        value={values.isUseCredit}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.checked) {
                            setFieldValue(
                              "creditAmount",
                              +_findWhere(customers, { id: values.customerId })
                                ?.credit > localHelpers.total(values.customerId)
                                ? +localHelpers.total(values.customerId)
                                : +_findWhere(customers, {
                                    id: values.customerId,
                                  })?.credit
                            );
                          } else {
                            setFieldValue("creditAmount", 0);
                          }
                        }}
                      />
                      <div
                        className={clsx(
                          "text-xs uppercase text-right font-light",
                          values.isUseCredit ? "text-gray-600" : "text-gray-300"
                        )}
                      >
                        Kredit:{" "}
                      </div>
                    </div>
                    <Typography.Currency
                      number={
                        +_findWhere(customers, { id: values.customerId })
                          ?.credit > localHelpers.total(values.customerId)
                          ? +localHelpers.total(values.customerId)
                          : +_findWhere(customers, {
                              id: values.customerId,
                            })?.credit
                      }
                    />

                    <div className="col-span-2">
                      <hr />
                    </div>
                    <div className="text-sm uppercase text-gray-600 text-right font-bold">
                      Grand Total:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        localHelpers.total(values.customerId) -
                        values.creditAmount
                      }
                    />
                  </div>
                </FormSummary>
              </div>
            </div>

            <ModalMolecule
              show={showInvoice}
              title="Invoice yang belum lunas"
              onClose={() => setShowInvoice(false)}
            >
              <Table.Wrapper>
                <Table.Header>
                  <Table.HeaderRow>
                    <Table.Heading>Kode</Table.Heading>
                    <Table.Heading align="right">Total Tagihan</Table.Heading>
                    <Table.Heading align="right">Pembayaran</Table.Heading>
                    <Table.Heading align="right">Kredit</Table.Heading>
                    <Table.Heading align="right">Kurang/Lebih</Table.Heading>
                  </Table.HeaderRow>
                </Table.Header>
                <Table.Body>
                  {customerInvoices(values.customerId)?.map((invoice, key) => {
                    if (invoice.status !== 3) {
                      return (
                        <Table.BodyRow key={key}>
                          <Table.Cell>
                            <BadgeButton
                              onClick={(e) =>
                                navigate("/invoice/detail/" + invoice.id)
                              }
                            >
                              {invoice.inv_code}
                            </BadgeButton>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              number={invoice.total_amount}
                            ></Typography.Currency>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              number={invoice.total_payment}
                            ></Typography.Currency>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              number={invoice.credit_amount}
                            ></Typography.Currency>
                          </Table.Cell>
                          <Table.Cell align="right">
                            <Typography.Currency
                              color={
                                Number(invoice.total_amount) >
                                Number(invoice.total_payment) +
                                  Number(invoice.credit_amount)
                                  ? "red"
                                  : "green"
                              }
                              number={
                                Number(invoice.total_payment) +
                                Number(invoice.credit_amount) -
                                Number(invoice.total_amount)
                              }
                            />
                          </Table.Cell>
                        </Table.BodyRow>
                      );
                    }
                  })}
                </Table.Body>
              </Table.Wrapper>
            </ModalMolecule>

            <div className="fixed bottom-2 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => {
                  setFieldValue(
                    "orderIds",
                    _filter(salesOrders, {
                      customer_id: values.customerId,
                      Invoice: null,
                    }).map((salesOrder) => salesOrder.id)
                  );
                  runValidations(values);
                }}
                type="submit"
              >
                Tambah Invoice
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default CreateInvoicePage;
