import { DateTime, Info } from "luxon";

function currency(number, locale = "id", currencyCode = "USD") {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(number);
}

function standardDate(date, locale = "en", format = "d MMMM yyyy") {
  let dt = DateTime.fromISO(date, { locale });
  return dt.toFormat(format);
}

function shortDate(date, locale = "en", format = "d/M") {
  let dt = DateTime.fromISO(date, { locale });
  return dt.toFormat(format);
}

function standardDay(date, locale = "en") {
  let dt = DateTime.fromISO(date, { locale });
  return dt.weekdayLong;
}

function dateNow(locale = "en", format = "d MMMM yyyy") {
  let dt = DateTime.fromISO(DateTime.now(), { locale });
  return dt.toFormat(format);
}

function dateForInput(date) {
  let dt = DateTime.fromISO(date);
  return dt.toFormat("yyyy-MM-dd");
}

export default {
  currency,
  standardDate,
  standardDay,
  dateNow,
  dateForInput,
  shortDate,
};

export {
  currency,
  standardDate,
  standardDay,
  dateNow,
  dateForInput,
  shortDate,
};
