import { Fragment } from "react";
import CreateVariantModal from "./createVariant";
import UpdateVariantModal from "./updateVariant";
import DeleteVariantModal from "./deleteVariant";
import CreateMenuCategoryModal from "./createMenuCategory";
import UpdateMenuCategoryModal from "./updateMenuCategory";
import DeleteMenuCategoryModal from "./deleteMenuCategory";
import CreateDailyMenuModal from "./createDailyMenu";
import UpdateDailyMenuModal from "./updateDailyMenu";
import DeleteDailyMenuModal from "./deleteDailyMenu";
import CreateShipmentCostCategoryModal from "./createShipmentCostCategory";
import UpdateShipmentCostCategoryModal from "./updateShipmentCostCategory";
import DeleteShipmentCostCategoryModal from "./deleteShipmentCostCategory";
import CreateCustomerModal from "./createCustomer";
import DeleteCustomerModal from "./deleteCustomer";
import DeleteSalesOrderModal from "./deleteSalesOrder";
import DeleteInvoiceModal from "./deleteInvoice";
import CreatePaymentModal from "./createPayment";
import UpdatePaymentModal from "./updatePayment";
import DeletePaymentModal from "./deletePayment";
import ConfirmPaymentModal from "./confirmPayment";
import CreateDeliverySlipModal from "./createDeliverySlip";
import CreateSingleDeliverySlipModal from "./createSingleDeliverySlip";
import CreateMenuStickerModal from "./createMenuSticker";
import CreateDeliveryReportModal from "./createDeliveryReport";
import CreateCreditModal from "./createCredit";
import ViewInvoiceModal from "./viewInvoice";
import ViewDailyMenuModal from "./viewDailyMenu";
import ViewReportModal from "./viewReport";
import ViewErrorMessageModal from "./viewErrorMessage";

const registeredItems = {
  "variant.create": CreateVariantModal,
  "variant.update": UpdateVariantModal,
  "variant.delete": DeleteVariantModal,
  "menuCategory.create": CreateMenuCategoryModal,
  "menuCategory.update": UpdateMenuCategoryModal,
  "menuCategory.delete": DeleteMenuCategoryModal,
  "dailyMenu.create": CreateDailyMenuModal,
  "dailyMenu.update": UpdateDailyMenuModal,
  "dailyMenu.delete": DeleteDailyMenuModal,
  "shipmentCostCategory.create": CreateShipmentCostCategoryModal,
  "shipmentCostCategory.update": UpdateShipmentCostCategoryModal,
  "shipmentCostCategory.delete": DeleteShipmentCostCategoryModal,
  "customer.create": CreateCustomerModal,
  "customer.delete": DeleteCustomerModal,
  "salesOrder.delete": DeleteSalesOrderModal,
  "invoice.delete": DeleteInvoiceModal,
  "payment.create": CreatePaymentModal,
  "payment.update": UpdatePaymentModal,
  "payment.delete": DeletePaymentModal,
  "payment.confirm": ConfirmPaymentModal,
  "credit.create": CreateCreditModal,
  "deliverySlip.create": CreateDeliverySlipModal,
  "singleDeliverySlip.create": CreateSingleDeliverySlipModal,
  "menuSticker.create": CreateMenuStickerModal,
  "deliveryReport.create": CreateDeliveryReportModal,
  "invoice.view": ViewInvoiceModal,
  "dailyMenuReport.view": ViewDailyMenuModal,
  "report.view": ViewReportModal,
  "errorMessage.view": ViewErrorMessageModal,
};

function AppModals({ items = [], onSuccess = {} }) {
  return (
    <Fragment>
      {items.map((item, key) => {
        const Component = registeredItems[item];
        return <Component key={key} onSuccess={onSuccess[item]} />;
      })}
    </Fragment>
  );
}

export default AppModals;
