import axios from "../axios";
import h from "../helpers";

const menuCategory = {
  index: async () => {
    const { data } = await axios.get(h.baseUrl("product-category"));
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("product-category/" + id));
    return data;
  },
  store: async (name, desc) => {
    const { data } = await axios.post(
      h.baseUrl('product-category'),
      { name, desc },
    );
    return data;
  },
  update: async (id, name, desc) => {
    const { data } = await axios.put(
      h.baseUrl('product-category/' + id),
      { name, desc },
    );
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(
      h.baseUrl('product-category/' + id),
    );
    return data;
  },
};

export default menuCategory;
