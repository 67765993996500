import { useApplicationModals } from "app/common/hooks";
import * as Yup from "yup";
import { useUpdateForm } from "app/integration/common/hooks";
import api from "app/integration/api";
import { useCollector } from "app/integration/common/hooks";
import { useFetcher } from "app/integration/common/hooks";
import { useEffect } from "react";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { SelectMolecule } from "app/components/molecules/select";
import { Button } from "app/components/atoms/button";

function UpdateDailyMenuModal({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("dailyMenu.update");

  const handleClose = () => closeModal("dailyMenu.update");
  const handleSuccess = () => {
    closeModal("dailyMenu.update");
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        menuCategoryId: null,
        name: "",
        price: 0,
        notes: "",
        deliveryDate: "",
        variantId: null,
      },
      validationSchema: Yup.object().shape({
        menuCategoryId: Yup.number().required("Menu Harian harus diisi"),
        name: Yup.string().required("Nama Menu harus diisi"),
        price: Yup.number().required("Harga harus diisi"),
        deliveryDate: Yup.date().required("Tanggal harus diisi"),
        variantId: Yup.number().required("Kategori Menu harus diisi"),
      }),
      onSubmit: async (values) => {
        await api.dailyMenu.update(
          values.id,
          values.menuCategoryId,
          values.name,
          values.price,
          values.notes,
          values.deliveryDate,
          values.variantId
        );
      },
      onSuccess: handleSuccess,
    });

  const [menuCategories] = useCollector({ modelName: "menuCategory" });

  const [variants] = useCollector({ modelName: "variant" });

  const [dailyMenu, setDailyMenu, loader, fetch] = useFetcher({
    modelName: "dailyMenu",
    payload,
  });

  useEffect(() => {
    setInitialValues({
      id: dailyMenu.id,
      menuCategoryId: dailyMenu.product_category_id,
      name: dailyMenu.name,
      price: dailyMenu.price,
      notes: dailyMenu.notes,
      deliveryDate: dailyMenu.delivery_date,
      variantId: dailyMenu.m_variant_id,
    });
  }, [dailyMenu]);

  return (
    <ModalMolecule
      show={showStates["dailyMenu.update"]}
      title="Ubah Kategori Menu"
      onClose={handleClose}
    >
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <InputMolecule
              label="Tanggal"
              type="date"
              name="deliveryDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.deliveryDate}
              errorMessage={
                errors.deliveryDate &&
                touched.deliveryDate &&
                errors.deliveryDate
              }
            />
            <SelectMolecule
              label="Kategori Menu"
              name="menuCategoryId"
              options={menuCategories.map((menuCategory) => ({
                value: menuCategory.id,
                label: menuCategory.name,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.menuCategoryId}
              errorMessage={
                errors.menuCategory &&
                touched.menuCategory &&
                errors.menuCategory
              }
            />
            <SelectMolecule
              label="Kategori Varian"
              name="variantId"
              options={variants.map((variant) => ({
                value: variant.id,
                label: variant.name,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.variantId}
              errorMessage={
                errors.variantId && touched.variantId && errors.variantId
              }
            />
            <InputMolecule
              label="Nama"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <InputMolecule
              label="Harga"
              type="number"
              name="price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              errorMessage={errors.price && touched.price && errors.price}
            />
            {/* <InputMolecule
                label="Catatan"
                type="text"
                name="notes"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
                errorMessage={errors.notes && touched.notes && errors.notes}
              /> */}
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Ubah Kategori Menu</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default UpdateDailyMenuModal;
