import clsx from "clsx";

function badgeBgColor(color) {
  return {
    green:
      "bg-success-100 dark:bg-success-800 text-success-800 dark:text-success-100",
    blue: "bg-info-100 dark:bg-info-800 text-info-800 dark:text-info-100",
    purple:
      "bg-primary-100 dark:bg-primary-800 text-primary-800 dark:text-primary-100",
    red: "bg-danger-100 dark:bg-danger-800 text-danger-800 dark:text-danger-100",
    yellow:
      "bg-warning-100 dark:bg-warning-800 text-warning-800 dark:text-warning-100",
    gray: "bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100",
  }[color];
}
function badgeSize(size) {
  return {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
    xl: "text-xl",
  }[size];
}

function Badge({ children, color, size = "xs" }) {
  return (
    <div
      className={clsx(
        "inline-block rounded-lg font-medium py-0.5 px-3 whitespace-nowrap",
        badgeBgColor(color),
        badgeSize(size)
      )}
    >
      {children}
    </div>
  );
}

export default Badge;
