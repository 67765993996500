import user from "./models/user";
import variant from "./models/variant";
import variantCategory from "./models/variantCategory";
import variantItem from "./models/variantItem";
import menuCategory from "./models/menuCategory";
import dailyMenu from "./models/dailyMenu";
import shipmentCostCategory from "./models/shipmentCostCategory";
import customer from "./models/customer";
import salesOrder from "./models/salesOrder";
import invoice from "./models/invoice";
import payment from "./models/payment";
import report from "./models/report";
import credit from "./models/credit";

export default {
  user,
  variant,
  variantCategory,
  variantItem,
  menuCategory,
  dailyMenu,
  shipmentCostCategory,
  customer,
  salesOrder,
  invoice,
  payment,
  report,
  credit,
};

export {
  user,
  variant,
  variantCategory,
  variantItem,
  menuCategory,
  dailyMenu,
  shipmentCostCategory,
  customer,
  salesOrder,
  invoice,
  payment,
  report,
  credit,
};
