import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useApplicationModals } from "app/common/hooks";
import { useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import CustomerPageTable from "./table";
import CustomerDetailSubPage from "./detail";

const breadcrumbItems = [{ label: "Customer", href: "/customer" }];

function useCustomerCollector() {
  const [customers, setCustomers, loader, collect] = useCollector({
    modelName: "customer",
  });

  const [query, setQuery] = useState("");
  const handleQueryChange = (e) => setQuery(e.target.value);

  useEffect(() => {
    collect({ search: query });
  }, [query]);

  return [customers, setCustomers, loader, collect, query, handleQueryChange];
}

function CustomerPage() {
  const { openModal, setPayloads } = useApplicationModals();

  const [customers, setCustomers, loader, collect, query, handleQueryChange] =
    useCustomerCollector();

  return (
    <MainLayout
      activeSidebarNavigation="customer"
      activeSidebarChild="customer"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Customer"
      headingButtons={[
        {
          label: "Tambah Customer",
          type: "menuButton",
          onClick: () => openModal("customer.create"),
          items: [
            {
              label: "Tambah / Kurang Kredit",
              onClick: () => {
                setPayloads("credit.create", { customers });
                openModal("credit.create");
              },
            },
          ],
        },
      ]}
    >
      <AppModals
        items={["customer.create", "customer.delete"]}
        onSuccess={{
          "customer.create": () => collect(),
          "customer.delete": () => collect(),
        }}
      />
      <AppModals items={["credit.create"]} onSuccess={collect} />
      <Routes>
        <Route
          path="/"
          element={
            <CustomerPageTable
              customers={customers}
              onDeleteCustomerClick={(id) => {
                setPayloads("customer.delete", { id });
                openModal("customer.delete");
              }}
              query={query}
              onQueryChange={handleQueryChange}
            />
          }
        />
        <Route
          path="/detail/:id"
          element={
            <CustomerDetailSubPage
              customers={customers}
              query={query}
              onQueryChange={handleQueryChange}
            />
          }
        />
      </Routes>
    </MainLayout>
  );
}

export default CustomerPage;
