import clsx from 'clsx';

function iconButtonSize(size) {
  return {
    xs: 'h-5 w-5',
    sm: 'h-7 w-7',
    md: 'h-9 w-9',
    lg: 'h-11 w-11',
  }[size];
}

function IconButton({ icon, size = 'sm', onClick = () => {} }) {
  const Icon = icon;

  return (
    <button
      type="button"
      className={clsx(
        "inline-flex items-center rounded-lg px-1 py-1 text-sm transition-colors duration-300 ease-in-out",
        "text-gray-400 hover:text-gray-600 focus:ring-gray-300 dark:text-gray-500 dark:hover:text-gray-300 dark:focus:ring-gray-500",
        iconButtonSize(size)
      )}
      onClick={onClick}
    >
      <Icon />
    </button>
  );
}

export default IconButton;
