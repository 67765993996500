import { TrashIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  Button,
  DottedOutlineButton,
  IconButton,
} from "app/components/atoms/button";
import { Card } from "app/components/atoms/card";
import { InputLabel } from "app/components/atoms/form";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { ModalMolecule } from "app/components/molecules/modal";
import { SelectMolecule, SelectSearchMolecule } from "app/components/molecules/select";
import api from "app/integration/api";
import {
  useCollector,
  useFetcher,
  useUpdateForm,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import str from "lib/str";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "style/height.module.css";
import {
  filter as _filter,
  findWhere as _findWhere,
  flatten as _flatten,
} from "underscore";
import * as Yup from "yup";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Order", href: "/sales_order" },
  { label: "Ubah Order", href: "#" },
];

function UpdateSalesOrderPage() {
  let { id } = useParams();
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate("/sales_order");
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        customerId: null,
        deliveryTo: null,
        addressId: null,
        deliveryTime: null,
        deliveryDate: "",
        noteOrder: "",
        orderDetails: [
          {
            dailyMenuId: null,
            variantItemIds: [],
            qty: 0,
            unitPrice: 0,
            notes: "",
          },
        ],
        order_code: "",
      },
      validationSchema: Yup.object().shape({
        customerId: Yup.number().required("Customer harus diisi"),
        deliveryTo: Yup.number().required("Customer Tujuan harus diisi"),
        addressId: Yup.number().required("Alamat harus diisi"),
        deliveryTime: Yup.number().required("Waktu Kirim harus diisi"),
        deliveryDate: Yup.date().required("Tanggal Kirim harus diisi"),
        orderDetails: Yup.array()
          .of(
            Yup.object().shape({
              dailyMenuId: Yup.number().required("Menu harus diisi"),
              variantItemIds: Yup.array().of(Yup.number()),
              qty: Yup.number().required("Qty harus diisi"),
              unitPrice: Yup.number().required("Harga harus diisi"),
            })
          )
          .min(1, "Minimal harus ada 1 Item yang dibeli."),
      }),
      onSubmit: async (values) => {
        await api.salesOrder.update(
          values.id,
          values.customerId,
          values.deliveryTo,
          values.addressId,
          values.deliveryTime,
          values.deliveryDate,
          values.noteOrder,
          localHelpers.selectedShipmentCostCategory(
            values.deliveryTo,
            values.addressId
          )?.price,
          localHelpers.shipmentCostDiscount(
            values.deliveryTo,
            values.addressId,
            values.orderDetails
              .map((orderDetail) => orderDetail.qty)
              .reduce((partialSum, a) => partialSum + a, 0)
          ),
          values.orderDetails.map((orderDetail) => ({
            product_id: orderDetail.dailyMenuId,
            qty: orderDetail.qty,
            unit_price: orderDetail.unitPrice,
            notes: orderDetail.notes,
            variants: orderDetail.variantItemIds.map((variantItemId) => {
              const selectedVariantItem =
                localHelpers.variantItemById(variantItemId);
              return {
                variant_category_id: selectedVariantItem.id,
                name: selectedVariantItem.name,
                price: selectedVariantItem.price,
              };
            }),
          }))
        );
      },
      onSuccess: handleSuccess,
    });

  const [salesOrder, setSalesOrder, loader, fetch] = useFetcher({
    modelName: "salesOrder",
    payload: { id },
  });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Ubah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  useEffect(() => {
    setInitialValues({
      id: salesOrder.id,
      customerId: salesOrder.customer_id,
      deliveryTo: salesOrder.delivery_to,
      addressId: salesOrder.address_id,
      deliveryTime: salesOrder.delivery_time,
      deliveryDate: salesOrder.delivery_date,
      noteOrder: salesOrder.note_order,
      orderDetails: salesOrder?.OrderItems?.map((orderItem) => ({
        id: orderItem.id,
        dailyMenuId: orderItem.product_id,
        variantItemIds: orderItem.variants.map(
          (item) => item.variant_category_id
        ),
        qty: orderItem.qty,
        unitPrice: orderItem.unit_price,
        notes: orderItem.notes,
      })),
      order_code: salesOrder?.order_code,
    });
  }, [salesOrder]);

  const [customers] = useCollector({ modelName: "customer" });
  const [dailyMenus] = useCollector({ modelName: "dailyMenu" });
  const [variants] = useCollector({ modelName: "variant" });

  const localHelpers = {
    unselectedDailyMenusWithCurrentSelectedValue: (
      orderDetails,
      currentSelectedDailyMenuId,
      requestedDate
    ) => {
      const selectedDailyMenuIds = orderDetails?.map(
        (orderDetail) => orderDetail.dailyMenuId
      );
      // const filteredDailyMenus = _filter(
      //   dailyMenus,
      //   (dailyMenu) =>
      //     (currentSelectedDailyMenuId !== undefined &&
      //       currentSelectedDailyMenuId !== null &&
      //       dailyMenu.id === currentSelectedDailyMenuId) ||
      //     !selectedDailyMenuIds.includes(dailyMenu.id)
      // );
      const filteredDailyMenus = dailyMenus;
      return _filter(
        filteredDailyMenus,
        (dailyMenu) =>
          str.standardDate(dailyMenu.delivery_date) ===
          str.standardDate(requestedDate)
      );
    },
    variantItemsByVariantId: (variantId) => {
      const variantCategories = _findWhere(variants, {
        id: variantId,
      })?.VariantCategories;
      const variantItems = _flatten(
        variantCategories?.map((variantCategory) => variantCategory.Variants)
      );
      return variantItems;
    },
    variantItemById: (variantItemId) => {
      const variantItems = _flatten(
        variants.map((variant) => {
          return _flatten(
            variant.VariantCategories.map(
              (variantCategory) => variantCategory.Variants
            )
          );
        })
      );
      return _findWhere(variantItems, { id: variantItemId });
    },
    subtotal: (orderItems) => {
      let subtotal = orderItems
        ?.map((orderItem) => +orderItem.qty * +orderItem.unitPrice)
        .reduce((partialSum, a) => partialSum + a, 0);
      let variantTotal = orderItems
        ?.map((orderItem) => {
          return (
            orderItem?.variantItemIds
              ?.map((variantItemId) =>
                localHelpers.variantItemById(variantItemId)
              )
              ?.map((variantItem) => +variantItem?.price)
              ?.reduce((partialSum, a) => partialSum + a, 0) * orderItem.qty
          );
        })
        ?.reduce((partialSum, a) => partialSum + a, 0);

      return +subtotal + +variantTotal;
    },
    selectedShipmentCostCategory: (deliveryTo, addressId) => {
      return _findWhere(_findWhere(customers, { id: deliveryTo })?.Addresses, {
        id: addressId,
      })?.DeliveryCategory;
    },
    shipmentCostDiscount: (deliveryTo, addressId, qty) => {
      const selectedShipmentCostCategory =
        localHelpers.selectedShipmentCostCategory(deliveryTo, addressId);
      const deliveryDiscounts = selectedShipmentCostCategory?.DeliveryDiscounts;
      if (deliveryDiscounts?.length === 0) return 0;

      for (let i = qty; i >= 0; i--) {
        const deliveryDiscount = _findWhere(deliveryDiscounts, {
          condition: i + "",
        });
        if (deliveryDiscount !== undefined) {
          return deliveryDiscount.discount_price;
        }
      }

      return 0;
    },
  };

  return (
    <MainLayout
      activeSidebarNavigation="order"
      breadcrumbItems={breadcrumbItems}
      pageTitle={
        <p className="flex items-center gap-2">
          {initialValues?.order_code}
          {salesOrder.is_valid_order ? (
            <div className="group relative h-full flex items-center">
              <Badge color="green" size="lg">
                Valid Order
              </Badge>
              <p className="hidden group-hover:block absolute top-0 left-full bg-primary-900 text-white text-xs p-2 font-light">
                Valid Order harus diproses oleh kitchen.
              </p>
            </div>
          ) : (
            <div className="group relative h-full flex items-center">
              <Badge color="red" size="lg">
                Invalid Order
              </Badge>
              <p className="hidden group-hover:block absolute top-0 left-full bg-primary-900 text-white text-xs p-2 font-light">
                Invalid Order tidak boleh diproses oleh kitchen.
              </p>
            </div>
          )}
        </p>
      }
      headingButtons={[
        {
          label:
            salesOrder.Invoice != null ? "Lihat Invoice" : "Generate Invoice",
          type: "menuButton",
          onClick: (e) =>
            navigate(
              salesOrder.Invoice != null
                ? "/invoice/detail/" + salesOrder.Invoice.id
                : "/invoice/create?customer_id=" + salesOrder.customer_id
            ),
          items: [
            {
              label: "Tambah Order",
              href: "/sales_order/create",
            },
            {
              label: "Print Surat Jalan",
              onClick: (e) => {
                setPayloads("singleDeliverySlip.create", {
                  data: salesOrder,
                });
                openModal("singleDeliverySlip.create");
                console.log("order is: ", salesOrder);
              },
            },
          ],
        },
      ]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <AppModals
        items={["singleDeliverySlip.create"]}
        onSuccess={{
          "singleDeliverySlip.create": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <FormHeader>
              <SelectSearchMolecule
                label="Customer"
                name="customerId"
                options={customers?.map((customer) => ({
                  value: customer.id,
                  label:
                    customer.first_name +
                    " " +
                    customer.middle_name +
                    " " +
                    customer.last_name,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.customerId}
                errorMessage={
                  errors.customerId && touched.customerId && errors.customerId
                }
              />
              <SelectSearchMolecule
                label="Customer Tujuan"
                name="deliveryTo"
                options={customers?.map((customer) => ({
                  value: customer.id,
                  label:
                    customer.first_name +
                    " " +
                    customer.middle_name +
                    " " +
                    customer.last_name,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deliveryTo}
                errorMessage={
                  errors.deliveryTo && touched.deliveryTo && errors.deliveryTo
                }
              />
              <SelectMolecule
                label="Alamat Kirim"
                name="addressId"
                options={_findWhere(customers, {
                  id: values.deliveryTo,
                })?.Addresses?.map((address) => ({
                  value: address.id,
                  label: address.address,
                }))}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.addressId}
                errorMessage={
                  errors.addressId && touched.addressId && errors.addressId
                }
              />
              <SelectMolecule
                label="Waktu Kirim"
                name="deliveryTime"
                options={[
                  { value: 0, label: "Pagi" },
                  { value: 1, label: "Default" },
                ]}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deliveryTime}
                errorMessage={
                  errors.deliveryTime &&
                  touched.deliveryTime &&
                  errors.deliveryTime
                }
              />
              {/* <InputMolecule
                label="Tanggal Kirim"
                type="date"
                name="deliveryDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deliveryDate}
                errorMessage={
                  errors.deliveryDate &&
                  touched.deliveryDate &&
                  errors.deliveryDate
                }
              /> */}
              <div className="h-full">
                <InputLabel>Tanggal Kirim</InputLabel>
                <div className="flex items-center h-11 px-2 border rounded-lg">
                  <Typography.Date date={values.deliveryDate} />
                </div>
              </div>
            </FormHeader>
            <div className="py-2 overflow-y-visible">
              <div className="w-full">
                <FieldArray name="orderDetails">
                  {(arrayHelpers) => (
                    <div className="table-content-height overflow-y-auto">
                      <Table.Wrapper asIndex={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Menu</Table.Heading>
                            <Table.Heading>Varian</Table.Heading>
                            <Table.Heading>Catatan</Table.Heading>
                            <Table.Heading>Qty</Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Harga Satuan</p>
                            </Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Subtotal</p>
                            </Table.Heading>
                            <Table.Heading></Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {values.orderDetails?.map((orderDetail, key) => (
                            <Table.BodyRow key={key}>
                              {/* {console.log("order: ", orderDetail)} */}
                              <Table.Cell>
                                <SelectMolecule
                                  hideLabel={true}
                                  name={`orderDetails[${key}].dailyMenuId`}
                                  options={localHelpers
                                    .unselectedDailyMenusWithCurrentSelectedValue(
                                      values.orderDetails,
                                      orderDetail.dailyMenuId,
                                      values.deliveryDate
                                    )
                                    ?.sort(function (a, b) {
                                      if (
                                        a.ProductCategory.name?.toLowerCase() <
                                        b.ProductCategory.name?.toLowerCase()
                                      )
                                        return -1;
                                      if (
                                        a.ProductCategory.name?.toLowerCase() >
                                        b.ProductCategory.name?.toLowerCase()
                                      )
                                        return 1;
                                      return 0;
                                    })
                                    ?.map((dailyMenu) => ({
                                      value: dailyMenu.id,
                                      label:
                                        dailyMenu.ProductCategory.name +
                                        ": " +
                                        dailyMenu.name,
                                    }))}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue(
                                      `orderDetails[${key}].unitPrice`,
                                      _findWhere(dailyMenus, {
                                        id: e.target.value,
                                      }).price
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.dailyMenuId}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].dailyMenuId`
                                    ) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].dailyMenuId`
                                    ) &&
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].dailyMenuId`
                                    )
                                  }
                                  absolute={false}
                                />
                              </Table.Cell>
                              <Table.Cell className="max-w-xs">
                                <VariantModal
                                  name={`orderDetails[${key}].variantItemIds`}
                                  options={_findWhere(variants, {
                                    id: _findWhere(dailyMenus, {
                                      id: orderDetail.dailyMenuId,
                                    })?.m_variant_id,
                                  })}
                                  onChange={handleChange}
                                  value={orderDetail.variantItemIds}
                                />
                                {/* {_findWhere(dailyMenus, {
                                  id: orderDetail.dailyMenuId,
                                })?.m_variant_id !== undefined && (
                                  <PlainMultiselect
                                    name={`orderDetails[${key}].variantItemIds`}
                                    options={localHelpers
                                      .variantItemsByVariantId(
                                        _findWhere(dailyMenus, {
                                          id: orderDetail.dailyMenuId,
                                        })?.m_variant_id
                                      )
                                      .map((variantItem) => ({
                                        value: variantItem.id,
                                        label:
                                          variantItem.name +
                                          " " +
                                          variantItem.price,
                                      }))}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={orderDetail.variantItemIds}
                                    errorMessage={
                                      getIn(
                                        errors,
                                        `orderDetails[${key}].variantItemIds`
                                      ) &&
                                      getIn(
                                        touched,
                                        `orderDetails[${key}].variantItemIds`
                                      ) &&
                                      getIn(
                                        errors,
                                        `orderDetails[${key}].variantItemIds`
                                      )
                                    }
                                  />
                                )} */}
                              </Table.Cell>
                              <Table.Cell>
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`orderDetails[${key}].notes`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.notes}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].notes`
                                    ) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].notes`
                                    ) &&
                                    getIn(errors, `orderDetails[${key}].notes`)
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell className="w-24">
                                <InputMolecule
                                  hideLabel={true}
                                  type="number"
                                  name={`orderDetails[${key}].qty`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.qty}
                                  errorMessage={
                                    getIn(errors, `orderDetails[${key}].qty`) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].qty`
                                    ) &&
                                    getIn(errors, `orderDetails[${key}].qty`)
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {/* <InputMolecule
                                  hideLabel={true}
                                  type="number"
                                  name={`orderDetails[${key}].unitPrice`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.unitPrice}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].unitPrice`
                                    ) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].unitPrice`
                                    ) &&
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].unitPrice`
                                    )
                                  }
                                /> */}
                                <p className="text-right">
                                  <Typography.Currency
                                    number={orderDetail.unitPrice}
                                  />
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <p className="text-right">
                                  <Typography.Currency
                                    number={
                                      orderDetail.qty *
                                      (+orderDetail.unitPrice +
                                        +orderDetail.variantItemIds
                                          ?.map((variantItemId) => {
                                            const selectedVariantItem =
                                              localHelpers.variantItemById(
                                                variantItemId
                                              );
                                            return +selectedVariantItem?.price;
                                          })
                                          .reduce(
                                            (partialSum, a) => partialSum + a,
                                            0
                                          ))
                                    }
                                  />
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <IconButton
                                  icon={TrashIcon}
                                  onClick={(e) => arrayHelpers.remove(key)}
                                />
                              </Table.Cell>
                            </Table.BodyRow>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>
                      <div>
                        <div className="">
                          <DottedOutlineButton
                            type="button"
                            onClick={(e) =>
                              arrayHelpers.push({
                                dailyMenuId: null,
                                variantItemIds: [],
                                notes: "",
                                qty: 1,
                                unitPrice: 0,
                              })
                            }
                          >
                            Tambah Item
                          </DottedOutlineButton>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <FormSummary>
                  <div className="flex-grow w-full md:max-w-lg order-last md:order-first">
                    <InputMolecule
                      label="Catatan"
                      type="text"
                      name="noteOrder"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.noteOrder}
                      errorMessage={
                        errors.noteOrder &&
                        touched.noteOrder &&
                        errors.noteOrder
                      }
                    />
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.subtotal(values.orderDetails)}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        localHelpers.selectedShipmentCostCategory(
                          values.deliveryTo,
                          values.addressId
                        )?.price
                      }
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Diskon Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.shipmentCostDiscount(
                        values.deliveryTo,
                        values.addressId,
                        values.orderDetails
                          ?.map((orderDetail) => orderDetail.qty)
                          ?.reduce((partialSum, a) => partialSum + a, 0)
                      )}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Total:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        +localHelpers.subtotal(values.orderDetails) +
                        +localHelpers.selectedShipmentCostCategory(
                          values.deliveryTo,
                          values.addressId
                        )?.price -
                        +localHelpers.shipmentCostDiscount(
                          values.deliveryTo,
                          values.addressId,
                          values.orderDetails
                            ?.map((orderDetail) => orderDetail.qty)
                            ?.reduce((partialSum, a) => partialSum + a, 0)
                        )
                      }
                    />
                  </div>
                </FormSummary>
              </div>
            </div>

            <div className="fixed bottom-2 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button onClick={() => runValidations(values)} type="submit">
                Ubah Order
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

function VariantModal({ name, options = [], onChange = () => {}, value = [] }) {
  const [showVariantModal, setShowVariantModal] = useState(false);

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(localValue)) {
      setLocalValue(value);
    }
  }, [value]);

  const localOnChange = (newValue) => {
    setLocalValue(newValue);
    onChange({
      target: {
        value: newValue,
        name: name,
      },
    });
  };

  return (
    <div className="flex flex-wrap gap-1 items-center">
      {localValue.map((id, key) => (
        <Badge color="green" key={key}>
          {/* {
              JSON.stringify(
                _flatten(options?.VariantCategories?.map(variantCategory => variantCategory.Variants))
              )
            } */}
          {
            _findWhere(
              _flatten(
                options?.VariantCategories?.map(
                  (variantCategory) => variantCategory.Variants
                )
              ),
              { id }
            )?.name
          }{" "}
          (
          <Typography.Currency
            fontSize="xs"
            number={
              _findWhere(
                _flatten(
                  options?.VariantCategories?.map(
                    (variantCategory) => variantCategory.Variants
                  )
                ),
                { id }
              )?.price
            }
          />
          )
        </Badge>
      ))}
      <div className="inline-flex space-x-2"></div>
      <BadgeButton
        onClick={(e) => {
          setShowVariantModal(true);
        }}
      >
        Pilih Varian
      </BadgeButton>
      <ModalMolecule
        show={showVariantModal}
        title="Pilih Varian"
        onClose={(e) => {
          setShowVariantModal(false);
        }}
      >
        <div className="grid grid-cols-3 items-center gap-y-1 gap-x-2 w-fit">
          <div className="uppercase text-xs font-light text-left text-gray-500">
            Varian:
          </div>
          <div className="text-xs font-semibold text-left col-span-2">
            {options.name}
          </div>
          <div className="uppercase text-xs font-light text-left text-gray-500">
            Deskripsi:
          </div>
          <div className="text-xs font-semibold text-left col-span-2">
            {options.desc}
          </div>
        </div>
        {options?.VariantCategories?.map((variantCategory, key) => (
          <Card key={key}>
            <div className="flex items-center gap-2">
              <InputLabel>{variantCategory.name}</InputLabel>
              <InputLabel>
                (
                {variantCategory.is_multiple
                  ? "Boleh Lebih Dari Satu"
                  : "Pilih Salah Satu"}
                )
              </InputLabel>
            </div>
            <div className="grid grid-cols-2 gap-1">
              {variantCategory?.Variants?.map((variantItem, vKey) => (
                <div key={vKey} className="gap-2 flex items-center">
                  <input
                    type="checkbox"
                    checked={localValue.includes(variantItem.id)}
                    onChange={(e) => {
                      const value = e.target.checked;
                      if (value) {
                        const newValue = localValue;
                        if (!variantCategory.is_multiple) {
                          variantCategory?.Variants?.map((variantItem) => {
                            const index = newValue.indexOf(variantItem.id);
                            if (index > -1) {
                              newValue.splice(index, 1);
                            }
                          });
                        }
                        newValue.push(variantItem.id);
                        localOnChange(newValue);
                      } else {
                        const index = localValue.indexOf(variantItem.id);
                        if (index > -1) {
                          const newValue = localValue;
                          newValue.splice(index, 1);
                          localOnChange(newValue);
                        }
                      }
                    }}
                  />
                  <span className="text-xs capitalize">{variantItem.name}</span>
                  <span className="text-xs capitalize">
                    (
                    <Typography.Currency
                      fontSize="xs"
                      number={variantItem.price}
                    />
                    )
                  </span>
                </div>
              ))}
            </div>
          </Card>
        ))}
      </ModalMolecule>
    </div>
  );
}

export default UpdateSalesOrderPage;
