import * as Yup from "yup";
import storage from 'app/storage';
import api from "app/integration/api";
import { useUpdateForm } from "app/integration/common/hooks";
import { useFetcher } from "app/integration/common/hooks";
import { useState, useEffect } from "react";
import MainLayout from "app/layouts/main";
import { Button } from "app/components/atoms/button";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { Card } from "app/components/atoms/card";
import clsx from "clsx";

const breadcrumbItems = [
  { label: "User", href: "#" },
  { label: "Profil User", href: "#" },
];

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function UserProfilePage() {
  const token = storage.authentication.token();
  const id = parseJwt(token).id;
  const username = parseJwt(token).username;

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      },
      validationSchema: Yup.object().shape({
        currentPassword: Yup.string().required("Password lama harus diisi"),
        newPassword: Yup.string().required("Password lama harus diisi"),
        newPasswordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Konfirmasi Password salah'),
      }),
      onSubmit: async (values) => {
        await api.user.login(username, values.currentPassword);
        await api.user.update(
          id,
          values.newPassword,
        );
      }
    });

  return (
    <MainLayout
      activeSidebarNavigation="user"
      activeSidebarChild="user-profile"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Profil User"
      headingButtons={[]}
    >
      <div className="px-4 w-full sm:max-w-sm">
        <Card>
          <Formik
            {...{ initialValues, validationSchema, onSubmit }}
          >
            {({
              values,
              errors,
              status,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form className={clsx("px-2 py-1 overflow-y-auto ")} onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-4">
                  <InputMolecule
                    label="Password Sekarang"
                    type="password"
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleChange}
                    errorMessage={
                      errors.currentPassword &&
                      touched.currentPassword &&
                      errors.currentPassword
                    }
                  />
                  <InputMolecule
                    label="Password Baru"
                    type="password"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    errorMessage={
                      errors.newPassword &&
                      touched.newPassword &&
                      errors.newPassword
                    }
                  />
                  <InputMolecule
                    label="Konfirmasi Password Baru"
                    type="password"
                    name="newPasswordConfirmation"
                    value={values.newPasswordConfirmation}
                    onChange={handleChange}
                    errorMessage={
                      errors.newPasswordConfirmation &&
                      touched.newPasswordConfirmation &&
                      errors.newPasswordConfirmation
                    }
                  />
                  <Button type="submit">Ubah Password</Button>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      </div>
    </MainLayout>
  );
}

export default UserProfilePage;
