import { useApplicationModals } from "app/common/hooks";
import { useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from 'app/modals';
import Table from "app/components/atoms/table";
import { SearchInput } from "app/components/atoms/input";
import { IconButton } from "app/components/atoms/button";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";

const breadcrumbItems = [
  { label: "Settings", href: "#" },
  { label: "Jumlah Menu", href: "#" },
];

function MenuCategoryPage() {
  const { openModal, setPayloads } = useApplicationModals();

  const [menuCategories, setMenuCategories, loader, collect] = useCollector({
    modelName: "menuCategory",
  });

  return (
    <MainLayout
      activeSidebarNavigation="settings"
      activeSidebarChild="menu-category"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Jumlah Menu (harian)"
      headingButtons={
        [
          // {
          //   label: "Tambah Jumlah Menu",
          //   type: "button",
          //   onClick: () => openModal("menuCategory.create"),
          // },
        ]
      }
    >
      <AppModals
        items={[
          "menuCategory.create",
          "menuCategory.update",
          "menuCategory.delete",
        ]}
        onSuccess={{
          "menuCategory.create": () => collect(),
          "menuCategory.update": () => collect(),
          "menuCategory.delete": () => collect(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <Table.SearchBarItemWrapper>
              {/* <SearchInput placeholder="Search" /> */}
            </Table.SearchBarItemWrapper>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Nama Menu</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {menuCategories
              ?.sort(function (a, b) {
                if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
                if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
                return 0;
              })
              .map((menuCategory, key) => (
                <Table.BodyRow key={key}>
                  <Table.Cell>{menuCategory.name}</Table.Cell>
                  <Table.Cell>
                    <div className="flex">
                      {/* <IconButton
                        icon={PencilSquareIcon}
                        onClick={(e) => {
                          setPayloads("menuCategory.update", {
                            id: menuCategory.id,
                          });
                          openModal("menuCategory.update");
                        }}
                      />
                      <IconButton
                        icon={TrashIcon}
                        onClick={(e) => {
                          setPayloads("menuCategory.delete", {
                            id: menuCategory.id,
                          });
                          openModal("menuCategory.delete");
                        }}
                      /> */}
                    </div>
                  </Table.Cell>
                </Table.BodyRow>
              ))}
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default MenuCategoryPage;
