import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import api from "app/integration/api";
import {
  useCollector,
  useFetcher,
  useUpdateForm,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik } from "formik";
import { DateTime } from "luxon";
import { Fragment, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import style from "style/height.module.css";
import { filter as _filter, isNull } from "underscore";
import * as Yup from "yup";
import str from "lib/str";

const breadcrumbItems = [
  { label: "Sales", href: "/invoice" },
  { label: "Invoice", href: "/invoice" },
  { label: "Detail Invoice", href: "#" },
];

function InvoiceDetailPage() {
  const navigate = useNavigate();
  let { id } = useParams();

  const { openModal, setPayloads } = useApplicationModals();

  const handleSuccess = () => {
    navigate("/invoice");
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        customerId: null,
        totalAmount: 0,
        isUseCredit: false,
        isUsePlafon: false,
        invoiceDate: "",
        dueDate: "",
        creditAmount: 0,
        status: 0,
        note: "",
        orderIds: [],
        orders: [],
        customerPhone: "",
        inv_code: "",
        customerName: "",
      },
      validationSchema: Yup.object().shape({
        customerId: Yup.number().required("Customer harus diisi"),
        totalAmount: Yup.number(),
        invoiceDate: Yup.date().required("Tanggal Invoice harus diisi"),
        dueDate: Yup.date().required("Tanggal Jatuh Tempo harus diisi"),
        creditAmount: Yup.number(),
      }),
      onSubmit: async (values) => {
        await api.invoice.store(
          values.customerId,
          values.totalAmount,
          values.isUseCredit,
          values.isUsePlafon,
          values.invoiceDate,
          values.dueDate,
          values.creditAmount,
          values.status,
          values.note,
          values.orderIds,
          values.orders,
          values.customerPhone,
          values.inv_code,
          values.customerName
        );
      },
      onSuccess: handleSuccess,
    });

  const [invoice, setInvoice, loader, fetch] = useFetcher({
    modelName: "invoice",
    payload: { id },
  });

  useEffect(() => {
    setInitialValues({
      id: invoice.id,
      customerId: invoice.customer_id,
      totalAmount: invoice.total_amount,
      isUseCredit: invoice.is_use_credit,
      isUsePlafon: invoice.is_use_plafon,
      invoiceDate: invoice.createdAt,
      dueDate: invoice.due_date,
      creditAmount: invoice.credit_amount,
      status: invoice.status,
      note: invoice.note,
      orderIds: invoice?.Orders?.map((order) => order.id),
      orders: invoice?.Orders,
      customerPhone: invoice?.Customer?.phone_number,
      inv_code: invoice?.inv_code,
      customerName:
        invoice?.Customer?.first_name +
        (invoice?.Customer?.middle_name?.length > 0
          ? " " + invoice?.Customer?.middle_name
          : "") +
        (invoice?.Customer?.last_name?.length > 0
          ? " " + invoice?.Customer?.last_name
          : ""),
    });
  }, [invoice]);

  const [customers] = useCollector({ modelName: "customer" });
  const [salesOrders] = useCollector({ modelName: "salesOrder" });

  const localHelpers = {
    subtotal: (customerId) => {
      return _filter(salesOrders, {
        customer_id: customerId,
        invoice_id: invoice.id,
      })
        .map((salesOrder) => {
          return salesOrder?.OrderItems.map(
            (orderDetail) =>
              orderDetail.qty * orderDetail.unit_price +
              orderDetail.variants
                .map((variant) => +variant.price)
                .reduce((partialSum, a) => partialSum + a, 0) *
                orderDetail.qty
          ).reduce((partialSum, a) => partialSum + a, 0);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCost: (customerId) => {
      return _filter(salesOrders, {
        customer_id: customerId,
        invoice_id: invoice.id,
      })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    shipmentCostDiscount: (customerId) => {
      return _filter(salesOrders, {
        customer_id: customerId,
        invoice_id: invoice.id,
      })
        .map((salesOrder) => {
          return Number(salesOrder.delivery_fee_discount);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },
    total: (customerId) => {
      return (
        +localHelpers.subtotal(customerId) +
        +localHelpers.shipmentCost(customerId) -
        +localHelpers.shipmentCostDiscount(customerId)
      );
    },
    payment: (payments) => {
      var total = 0;
      payments?.map((payment, key) => (total += Number(payment.total_payment)));
      return total;
    },
  };

  function convertPaymentStatus(status) {
    switch (status) {
      case 1:
        return "Unpaid";
      case 2:
        return "Partial";
      case 3:
        return "Lunas";
      case 4:
        return "Cancel";
      default:
        break;
    }
  }

  function getOrderSummary() {
    var txt = "Halo ";
    var customer = customers?.find((customer) => {
      return customer.id === initialValues?.customerId;
    });
    txt += initialValues.customerName + ",%0a";
    txt += "Berikut adalah invoice anda:%0a";
    txt += "Nomor invoice: *" + initialValues?.inv_code + "*%0a%0a";

    initialValues?.orders?.map((salesOrder, key) => {
      txt +=
        "Order " +
        // salesOrder.order_code +
        // ": " +
        str.standardDay(salesOrder.delivery_date, "id") +
        " " +
        str.shortDate(salesOrder.delivery_date, "id") +
        "%0a";
      salesOrder.OrderItems.map((orderItem, nestedKey) => {
        txt +=
          "Item: " +
          orderItem?.Product?.ProductCategory?.name +
          ": " +
          orderItem?.Product?.name +
          " (" +
          orderItem.qty +
          ")" +
          // " @" +
          // str.currency(orderItem.unit_price) +
          "";
        if (orderItem.variants.length > 0) {
          txt +=
            "%0aReq: " +
            orderItem.variants.map((item) => item.name).join(", ") +
            "%0a";
        }
        txt += "%0a";
        // orderItem.variants.map((variantItem, nestedNestedKey) => {
        // txt +=
        //   "Request: " +
        //   variantItem.name + "%0a";
        // ": (" +
        // orderItem.qty + ")%0a";
        // " @" +
        // str.currency(variantItem.price) +
        // ")%0a"
        // });
      });
      txt += "%0a";
    });
    // txt +=
    //   "%0aSubtotal: " +
    //   str.currency(localHelpers.subtotal(initialValues.customerId)) +
    //   "%0a";
    // txt +=
    //   "Ongkir: " +
    //   str.currency(localHelpers.shipmentCost(initialValues.customerId)) +
    //   "%0a";
    // txt +=
    //   "Diskon Ongkir: -" +
    //   str.currency(
    //     localHelpers.shipmentCostDiscount(initialValues.customerId)
    //   ) +
    //   "%0a";
    // txt +=
    //   "%0aTotal: " +
    //   str.currency(localHelpers.total(initialValues.customerId)) +
    //   "%0a";
    if (initialValues.creditAmount > 0) {
      txt +=
        "Kredit Digunakan: " + str.currency(initialValues.creditAmount) + "%0a";
    }
    if (invoice.Payments?.length > 0) {
      txt +=
        "Pembayaran: -" +
        str.currency(localHelpers.payment(invoice.Payments)) +
        "%0a";
    }
    txt +=
      "*Sisa Tagihan: " +
      str.currency(
        localHelpers.total(initialValues.customerId) -
          initialValues.creditAmount -
          localHelpers.payment(invoice.Payments)
      ) +
      "*%0a";
    txt +=
      "%0a%0aUntuk melakukan pembayaran, harap transfer ke:%0aBank: *BCA*%0aAtas nama: *PT. Erista Dwatiga Teknologi*%0aNomor rekening: *8292822323*%0aSetelah melakukan pembayaran, *harap mengirimkan bukti pembayaran melalui WhatsApp*. Terima kasih!";

    if (txt.length > 0 && !isNull(document.getElementById("txtInvoice"))) {
      document.getElementById("txtInvoice").innerHTML = txt.replaceAll(
        "%0a",
        "\n"
      );
    }
    return txt;
  }

  function copyToClipboard() {
    // document.getElementById("txtInvoice").innerHTML = getOrderSummary();

    // Get the text field
    var copyText = document.getElementById("txtInvoice").innerHTML;
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = copyText;

    // Select the text field
    elem.select();
    elem.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(elem.value);

    // Alert the copied text
    // alert("Copied the text: " + elem.value);
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <MainLayout
      activeSidebarNavigation="invoice"
      breadcrumbItems={breadcrumbItems}
      pageTitle={initialValues?.inv_code}
      headingButtons={[
        {
          label: "Register Payment",
          type: "menuButton",
          onClick: (e) => {
            setPayloads("payment.create", { invoiceId: id });
            openModal("payment.create");
          },
          items: [
            {
              label: "Share via WhatsApp",
              href:
                "https://wa.me/" +
                +initialValues?.customerPhone +
                "?text=" +
                getOrderSummary(),
            },
            {
              label: "View Invoice",
              onClick: (e) => {
                setPayloads("invoice.view", {
                  data: initialValues,
                });
                openModal("invoice.view");
              },
            },
          ],
        },
      ]}
    >
      <AppModals
        items={["payment.create"]}
        onSuccess={{
          "payment.create": () => fetch(),
        }}
      />
      <AppModals
        items={["invoice.view"]}
        onSuccess={{
          "invoice.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
            ref={componentRef}
          >
            <FormHeader color="white" background="white" appearance="none">
              <div className="col-span-3 md:col-span-2 grid grid-cols-3">
                <span className="whitespace-nowrap text-gray-500 text-sm">
                  Nama:
                </span>
                <p className="whitespace-wrap text-sm col-span-2">
                  {values.customerName}
                  {invoice?.Customer?.deletedAt !== null ? (
                    <span className="text-danger-400 font-light text-xs">
                      {" "}
                      [deleted customer]
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <span className="whitespace-nowrap text-gray-500 text-sm">
                  Telepon:
                </span>
                <p className="whitespace-nowrap text-sm col-span-2">
                  {values.customerPhone}
                </p>
                <span className="whitespace-nowrap text-gray-500 text-sm">
                  Tanggal Invoice:
                </span>
                <Typography.Date
                  date={values.invoiceDate}
                  size="sm"
                ></Typography.Date>
              </div>
              <span className="absolute right-2 top-1 whitespace-nowrap text-right col-span-2 text-sm font-medium uppercase">
                {invoice.status === 3 ? (
                  <Badge color="green" size="lg">
                    {convertPaymentStatus(invoice.status)}
                  </Badge>
                ) : (
                  <></>
                )}
                {invoice.status === 2 ? (
                  <Badge color="yellow" size="lg">
                    {convertPaymentStatus(invoice.status)}
                  </Badge>
                ) : (
                  <></>
                )}
                {invoice.status === 1 ? (
                  <Badge color="red" size="lg">
                    {convertPaymentStatus(invoice.status)}
                  </Badge>
                ) : (
                  <></>
                )}
              </span>
              {/* <InputMolecule
                label="Tanggal Invoice"
                type="date"
                name="invoiceDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invoiceDate}
                errorMessage={
                  errors.invoiceDate &&
                  touched.invoiceDate &&
                  errors.invoiceDate
                }
              /> */}
              {/* <InputMolecule
                label="Tanggal Jatuh Tempo"
                type="date"
                name="dueDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dueDate}
                errorMessage={
                  errors.dueDate && touched.dueDate && errors.dueDate
                }
              /> */}
              {/* <InputMolecule
                label="Catatan"
                type="text"
                name="note"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.note}
                errorMessage={errors.note && touched.note && errors.note}
              /> */}
            </FormHeader>
            <div className="py-2 overflow-y-visible col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="w-full">
                {/* <p>{initialValues.orderIds}</p> */}
                <FieldArray name="orderDetails">
                  {(arrayHelpers) => (
                    <div className="table-content-height overflow-y-auto">
                      <Table.Wrapper asIndex={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Order</Table.Heading>
                            <Table.Heading className="text-right">
                              Qty
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Harga Satuan
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Subtotal
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Ongkir
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Diskon Ongkir
                            </Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {/* {_filter(salesOrders, {
                            customer_id: values.customerId,
                          })?.map((salesOrder, key) => ( */}
                          {initialValues?.orders?.map((salesOrder, key) => (
                            <Fragment key={key}>
                              <Table.BodyRow>
                                <Table.Cell
                                  size="sm"
                                  fontWeight="semibold"
                                  background={100}
                                >
                                  <div className="flex gap-4 items-center">
                                    {salesOrder.order_code}
                                    <Typography.Date
                                      date={salesOrder.delivery_date}
                                      size="sm"
                                    />
                                    <Badge
                                      color={
                                        salesOrder.is_valid_order
                                          ? "green"
                                          : "red"
                                      }
                                    >
                                      {salesOrder.is_valid_order
                                        ? "Valid"
                                        : DateTime.fromISO(
                                            salesOrder.delivery_date
                                          )
                                            .set({
                                              hour: 0,
                                              minute: 0,
                                              second: 0,
                                              millisecond: 0,
                                            })
                                            .diff(
                                              DateTime.now().set({
                                                hour: 0,
                                                minute: 0,
                                                second: 0,
                                                millisecond: 0,
                                              }),
                                              ["days"]
                                            )
                                            .toObject().days < 0
                                        ? "Invalid / Expired"
                                        : "Invalid"}
                                    </Badge>
                                  </div>
                                </Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                ></Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                ></Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                ></Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                  className="text-right"
                                >
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee}
                                  />
                                </Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                  className="text-right"
                                >
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee_discount}
                                  />
                                </Table.Cell>
                              </Table.BodyRow>
                              {salesOrder.OrderItems.map(
                                (orderItem, nestedKey) => (
                                  <Fragment key={nestedKey}>
                                    <Table.BodyRow>
                                      <Table.Cell size="sm">
                                        <span className="ml-5">
                                          {
                                            orderItem?.Product?.ProductCategory
                                              ?.name
                                          }
                                          {": "}
                                          {orderItem?.Product?.name}
                                        </span>
                                      </Table.Cell>
                                      <Table.Cell
                                        size="sm"
                                        className="text-right"
                                      >
                                        {orderItem.qty}
                                      </Table.Cell>
                                      <Table.Cell
                                        size="sm"
                                        className="text-right"
                                      >
                                        <Typography.Currency
                                          number={orderItem.unit_price}
                                        />
                                      </Table.Cell>
                                      <Table.Cell
                                        size="sm"
                                        className="text-right"
                                      >
                                        <Typography.Currency
                                          number={
                                            +orderItem.qty *
                                            +orderItem.unit_price
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.BodyRow>
                                    {orderItem.variants.map(
                                      (item, nestedNestedKey) => (
                                        <Table.BodyRow key={nestedNestedKey}>
                                          <Table.Cell
                                            size="sm"
                                            className="pl-11"
                                          >
                                            {item.name}
                                          </Table.Cell>
                                          <Table.Cell
                                            size="sm"
                                            className="text-right"
                                          >
                                            {orderItem.qty}
                                          </Table.Cell>
                                          <Table.Cell
                                            size="sm"
                                            className="text-right"
                                          >
                                            <Typography.Currency
                                              number={item.price}
                                            />
                                          </Table.Cell>
                                          <Table.Cell className="text-right">
                                            <Typography.Currency
                                              number={
                                                item.price * orderItem.qty
                                              }
                                            />
                                          </Table.Cell>
                                        </Table.BodyRow>
                                      )
                                    )}
                                  </Fragment>
                                )
                              )}
                            </Fragment>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>
                    </div>
                  )}
                </FieldArray>
                <FormSummary big>
                  <div className="flex-grow w-full md:max-w-lg order-last md:order-first">
                    <h2 className="text-xs uppercase print:hidden">
                      Teks Invoice
                    </h2>
                    <p
                      className="h-24 overflow-y-auto border py-1 px-2 rounded-md bg-white print:hidden"
                      id="txtInvoice"
                    >
                      {" "}
                    </p>

                    <div className="flex justify-end pt-1 print:hidden">
                      <span
                        className="flex gap-1 cursor-pointer w-fit text-primary-600 text-xs"
                        onClick={copyToClipboard}
                      >
                        <ClipboardDocumentIcon className="h-4 w-4"></ClipboardDocumentIcon>
                        Copy
                      </span>
                    </div>
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.subtotal(values.customerId)}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.shipmentCost(values.customerId)}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Diskon Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        -localHelpers.shipmentCostDiscount(values.customerId)
                      }
                    />
                    {/* <div className="col-span-2 my-3"></div> */}

                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Total:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.total(values.customerId)}
                    />
                    <div className="col-span-2">
                      <hr />
                    </div>
                    <div className="flex justify-between">
                      {/* <input
                          readOnly={true}
                          disabled={true}
                          type="checkbox"
                          name="isUseCredit"
                          value={values.isUseCredit}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.checked) {
                              setFieldValue(
                                "creditAmount",
                                +_findWhere(customers, {
                                  id: values.customerId,
                                })?.credit
                              );
                            } else {
                              setFieldValue("creditAmount", 0);
                            }
                          }}
                        /> */}
                      <div
                        className={clsx(
                          "text-xs uppercase text-right font-light",
                          values.isUseCredit ? "text-gray-600" : "text-gray-300"
                        )}
                      >
                        Kredit Customer:{" "}
                      </div>
                    </div>
                    <div
                      className={clsx(
                        values.isUseCredit ? "" : "text-gray-300"
                      )}
                    >
                      <Typography.Currency number={-values.creditAmount} />
                    </div>

                    {invoice.Payments?.map((payment, key) => (
                      <>
                        <div
                          className="text-xs uppercase text-primary-600 text-right font-light"
                          key={key}
                        >
                          Pembayaran:{" "}
                        </div>
                        <Typography.Currency number={payment.total_payment} />
                      </>
                    ))}
                    <div className="text-sm uppercase text-gray-600 text-right font-bold">
                      Sisa Tagihan:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        localHelpers.total(values.customerId) -
                        +values.creditAmount -
                        localHelpers.payment(invoice.Payments)
                      }
                    />
                  </div>
                </FormSummary>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default InvoiceDetailPage;
