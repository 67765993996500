import { useState, useEffect, Fragment } from 'react';
import Sidebar from 'app/components/atoms/sidebar';
import EristaLogoLandscape from "img/erista-logo-landscape.png";
import EristaLogo from "img/erista-logo.png";

function SidebarOrganism({ sidebarLogo, navigations, onChange = () => {} }) {
  const [localNavigations, setLocalNavigations] = useState(navigations);

  const handleCollapsibleItemExpanded = (key) => {
    const newLocalNavigations = localNavigations;
    newLocalNavigations[key].expanded = true;
    setLocalNavigations(newLocalNavigations);
    onChange(newLocalNavigations);
  };
  const handleCollapsibleItemCollapsed = (key) => {
    const newLocalNavigations = localNavigations;
    newLocalNavigations[key].expanded = false;
    setLocalNavigations(newLocalNavigations);
    onChange(newLocalNavigations);
  };

  useEffect(() => {
    setLocalNavigations(navigations);
  }, [navigations]);

  return (
    <Sidebar.Wrapper>
      <Sidebar.Heading>
        <Sidebar.Logo
          image={sidebarLogo.image}
          collapsedImage={sidebarLogo.collapsedImage}
          imageAlt={sidebarLogo.imageAlt}
          collapsedImageAlt="Erista Logo"
        />
      </Sidebar.Heading>
      <Sidebar.Body>
        <Sidebar.Navigation.Wrapper>
          {localNavigations.map((navigation, key) => (
            <Fragment key={key}>
              {!navigation.children ? (
                <Sidebar.Navigation.Item
                  icon={navigation.icon}
                  href={navigation.href}
                  active={navigation.active}
                >
                  {navigation.label}
                </Sidebar.Navigation.Item>
              ) : (
                <Sidebar.NavigationCollapsible.Wrapper
                  expanded={navigation.expanded}
                >
                  {({ open }) => (
                    <Fragment>
                      <Sidebar.NavigationCollapsible.Item
                        icon={navigation.icon}
                        active={navigation.active}
                        expanded={navigation.expanded}
                        onExpanded={() => {
                          handleCollapsibleItemExpanded(key);
                        }}
                        onCollapsed={() => {
                          handleCollapsibleItemCollapsed(key);
                        }}
                      >
                        {navigation.label}
                      </Sidebar.NavigationCollapsible.Item>
                      <Sidebar.NavigationCollapsible.ChildrenWrapper>
                        {navigation.children.map((child, childKey) => (
                          <Sidebar.NavigationCollapsible.ChildItem
                            href={child.href}
                            active={child.active}
                            key={childKey}
                          >
                            {child.label}
                          </Sidebar.NavigationCollapsible.ChildItem>
                        ))}
                      </Sidebar.NavigationCollapsible.ChildrenWrapper>
                    </Fragment>
                  )}
                </Sidebar.NavigationCollapsible.Wrapper>
              )}
            </Fragment>
          ))}
        </Sidebar.Navigation.Wrapper>
      </Sidebar.Body>
      <div className="bg-primary-900 relative p-2 h-8">
        <a
          href="https://wa.me/6287772102323"
          className="absolute inset-0 flex-col items-center group-hover:flex xl:flex xl:opacity-100 opacity-0 group-hover:opacity-100 transition-all duration-500"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={EristaLogoLandscape}
            className="h-3 w-full object-contain"
            alt="Logo Erista"
          />
          <h2 className="text-gray-300 text-[8px] text-center font-thin whitespace-nowrap">
            by PT Erista Dwatiga Teknologi
          </h2>
        </a>
        <a
          href="https://wa.me/6287772102323"
          className="absolute inset-0 flex flex-col items-center opacity-100 group-hover:opacity-0 xl:hidden delay-300 transition-all duration-500"
          target="_blank"
          rel="noreferrer"
        >
          <img src={EristaLogo} className="h-5" alt="Logo Erista" />
          <h2 className="text-gray-300 text-[8px] text-center font-thin hidden">
            by PT Erista Dwatiga Teknologi
          </h2>
        </a>
      </div>
    </Sidebar.Wrapper>
  );
}

export default SidebarOrganism;
