import clsx from "clsx";

function PaginationButtons({ pagination }) {
  return (
    <nav aria-label="Page navigation example">
      <ul className="inline-flex -space-x-px py-2">
        <li>
          <a
            href="#"
            onClick={() => pagination.method.previousPage()}
            className={
              clsx(
                "px-3 py-2 ml-0 leading-tight border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white",
                pagination.method.previousPageIsNotAvailable() ? "bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-white" : "bg-white text-gray-500 dark:bg-gray-800 dark:text-gray-400"
              )
            }
          >
            Previous
          </a>
        </li>
        {
          [...Array(pagination.maximumPage).keys()].map(item => {
            return (
              <li key={item}>
                {
                  (item + 1) !== pagination.currentPage && (
                    <a onClick={e => pagination.method.setPage(item + 1)} href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{ item + 1 }</a>
                  )
                }
                {
                  (item + 1) === pagination.currentPage && (
                    <a onClick={e => pagination.method.setPage(item + 1)} href="#" aria-current="page" className="px-3 py-2 text-primary-600 border border-gray-300 bg-primary-50 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{ item + 1 }</a>
                  )
                }
              </li>
            )
          })
        }
        <li>
          <a
            href="#"
            onClick={() => pagination.method.nextPage()}
            className={
              clsx(
                "px-3 py-2 ml-0 leading-tight border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white",
                pagination.method.nextPageIsNotAvailable() ? "bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-white" : "bg-white text-gray-500 dark:bg-gray-800 dark:text-gray-400"
              )
            }
          >
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default PaginationButtons;
