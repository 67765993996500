import { useApplicationModals } from "app/common/hooks";
import { useEffect, useState } from "react";
import { ModalMolecule } from "app/components/molecules/modal";
import { useFetcher } from "app/integration/common/hooks";
import LogoSL from "img/sl-logo-fit.PNG";
import EristaLogo from "img/erista-logo.png";
import { DateTime } from "luxon";
import { Badge } from "app/components/atoms/badge";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "app/components/atoms/button";

function CreateDeliverySlipModal({ onSuccess = () => {} }) {
  const [queryParams, setQueryParams] = useState({
    delivery_slip: 1,
    order_ids: "",
  });

  const [report] = useFetcher({
    modelName: "report",
    methodName: "show",
    payload: { id: queryParams },
  });

  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("deliverySlip.create");

  const handleClose = () => closeModal("deliverySlip.create");
  const handleSuccess = () => {
    closeModal("deliverySlip.create");
    onSuccess();
  };

  useEffect(() => {
    if (payload?.data?.length > 0) {
      console.log("payload: ", payload.data);
      const order_ids = payload.data.map((item) => {
        return item.order_id;
      });

      setQueryParams({
        ...queryParams,
        order_ids: order_ids.join(","),
      });
    } else {
      console.log("payload kosong");
    }
  }, [payload]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function getMenuQty(orderItems, menu) {
    var qty = 0;
    orderItems?.map((orderItem) => {
      if (orderItem.menu === menu) {
        qty += orderItem.qty;
      }
    });
    return qty;
  }

  function getProductNameQty(listProduct) {
    var menu = [
      {
        menu: "A",
        name: "A",
        qty: 0,
      },
      {
        menu: "B",
        name: "B",
        qty: 0,
      },
    ];

    if (listProduct?.length > 0) {
      listProduct.map((product, index) => {
        if (product.menu === "A") {
          menu[0].qty += Number(product.qty);
          menu[0].name = product.product;
        }
        if (product.menu === "B") {
          menu[1].qty += Number(product.qty);
          menu[1].name = product.product;
        }
      });
    }

    return menu;
  }

  // const menu = getProductNameQty(report);

  return (
    <ModalMolecule
      show={showStates["deliverySlip.create"]}
      title="Print Surat Jalan"
      onClose={handleClose}
      size="xl"
    >
      {/* <div>
        {console.log("report is: ", report)}
        {report?.length > 0 &&
          report?.map((item, index) => (
            <div key={index}>
              <div>{item.customerName}</div>
            </div>
          ))}
      </div> */}
      <div
        ref={componentRef}
        className="text-sm font-light divide-y divide-dashed divide-gray-500 w-[827px] space-y-1 border border-gray-100 shadow-xl print:shadow-none print:border-none"
      >
        {report?.length > 0 &&
          report?.map((suratJalan, index) => (
            <div className="block py-2 px-8">
              <div className="h-[208px] w-full border border-gray-800 flex flex-col px-2 mt-1 relative overflow-clip">
                <div className="absolute top-1 right-0">
                  {suratJalan.deliveryTime === 0 ? (
                    <Badge color="green">Kirim Pagi</Badge>
                  ) : (
                    <></>
                  )}
                  <div className="border border-gray-100 px-1 mt-1 mr-1">
                    {getProductNameQty(suratJalan.listProduct).map((menu) => (
                      <div className="flex flex-row text-sm text-gray-600">
                        <p className="text-center">Total ({menu.menu}):</p>
                        <p className="w-6 text-right">{menu.qty}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="grid grid-cols-2 justify-between text-sm leading-tight">
                  <div className="">
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-20 text-gray-500">Order:</p>
                      <p>{suratJalan.order_code}</p>
                    </div>
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-20 text-gray-500">Customer:</p>
                      <p className="font-medium">{suratJalan.customerName}</p>
                    </div>
                    <div className="flex items-start gap-1 pr-1">
                      <p className="text-sm tracking-tighter leading-tight w-20 text-gray-500">
                        Alamat:
                      </p>
                      <p className="text-sm tracking-tighter leading-tight">
                        {suratJalan.customerAddress}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-12 text-gray-500">Tgl:</p>
                      <p>
                        {DateTime.fromISO(suratJalan.deliveryDate).toFormat(
                          "dd-MM-yyyy"
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-12 text-gray-500">Tel:</p>
                      <input
                        className="peer print:hidden accent-primary-600"
                        type="checkbox"
                        name={`rekapSuratJalan[${index}].showPhone`}
                        id={`rekapSuratJalan[${index}].showPhone`}
                        defaultChecked={false}
                      />
                      <p
                        htmlFor={`rekapSuratJalan[${index}].showPhone`}
                        className="peer-checked:print:block peer-checked:blur-none blur-sm print:hidden"
                      >
                        {suratJalan.customerTel}
                      </p>
                    </div>
                    <div className="flex items-start gap-1 pr-1">
                      <p className="text-sm tracking-tighter leading-tight w-12 text-gray-500">
                        Note:
                      </p>
                      <p className="text-sm tracking-tighter leading-tight pr-20">
                        {suratJalan?.note}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row flex-grow">
                  <div className="flex-grow flex flex-col">
                    <div className="flex-grow flex flex-col px-2 py-1 max-h-32 overflow-clip">
                      <div className="flex justify-between h-full">
                        <div className="w-full max-w-md">
                          {suratJalan.listProduct
                            .sort(function (a, b) {
                              if (a.menu?.toLowerCase() < b.menu?.toLowerCase())
                                return -1;
                              if (a.menu?.toLowerCase() > b.menu?.toLowerCase())
                                return 1;
                              return 0;
                            })
                            .map((menu, idx) => (
                              <div className="flex flex-row justify-between gap-1 w-full leading-tight">
                                <p className="flex-grow line-clamp-2 text-sm leading-tight">
                                  {menu.product}{" "}
                                  {menu.variants.length > 0 ? (
                                    <span className="text-sm tracking-tight text-gray-900 leading-tight">
                                      ({menu.variants.join(", ")}){" "}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {menu.note?.length > 0 ? (
                                    <span className="text-sm tracking-tight italic text-gray-900 leading-tight">
                                      "{menu.note}"
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                                <div className="flex">
                                  <p className="w-6 text-center text-sm leading-tight">
                                    ({menu.menu})
                                  </p>
                                  <p className="w-6 text-right text-sm leading-tight">
                                    {menu.qty}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>

                        <div className="h-full flex flex-col justify-end items-end">
                          <div className="flex flex-col justify-between h-fit gap-8">
                            <p className="w-full text-right text-sm">
                              Penerima,
                            </p>
                            <p className="flex flex-row justify-between text-[10px] text-gray-300 tracking-tighter whitespace-nowrap">
                              Nama dan Tanda Tangan
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-red-500 italic text-sm font-light">
                      note: Mohon segera simpan di kulkas untuk yang tidak
                      segera dimakan
                    </div>
                  </div>
                  <div className="w-40 h-full px-2 flex flex-col justify-end items-end">
                    {/* <h1 className="text-xl text-right font-normal text-gray-600">
                    Surat Jalan
                  </h1> */}

                    <img
                      className="h-full pl-2 object-contain object-bottom"
                      src={EristaLogo}
                      alt="logo Erista"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Surat Jalan
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default CreateDeliverySlipModal;
