import axios from "../axios";
import h from "../helpers";

const salesOrder = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("order"), { params });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("order/" + id));
    return data;
  },
  store: async (customer_id, delivery_to, address_id, delivery_time, delivery_date, note_order, delivery_fee, delivery_fee_discount, OrderItems) => {
    const { data } = await axios.post(
      h.baseUrl('order'),
      { customer_id, delivery_to, address_id, delivery_time, delivery_date, note_order, delivery_fee, delivery_fee_discount, OrderItems },
    );
    return data;
  },
  update: async (id, customer_id, delivery_to, address_id, delivery_time, delivery_date, note_order, delivery_fee, delivery_fee_discount, OrderItems) => {
    const { data } = await axios.put(
      h.baseUrl('order/' + id),
      { id, customer_id, delivery_to, address_id, delivery_time, delivery_date, note_order, delivery_fee, delivery_fee_discount, OrderItems },
    );
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(
      h.baseUrl('order/' + id),
    );
    return data;
  },
};

export default salesOrder;
