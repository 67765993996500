import axios from "../axios";
import h from "../helpers";

const payment = {
  index: async () => {
    const { data } = await axios.get(h.baseUrl("payment"));
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("payment/" + id));
    return data;
  },
  store: async (invoice_id, method, payment_date, payment_time, ref_number, bank_name, account_number, total_payment, status, mode) => {
    const { data } = await axios.post(h.baseUrl("payment"), {
      invoice_id,
      method,
      payment_date,
      payment_time,
      ref_number,
      bank_name,
      account_number,
      total_payment,
      status,
      mode,
    });
    return data;
  },
  update: async (id, invoice_id, method, payment_date, payment_time, ref_number, bank_name, account_number, total_payment, status, mode) => {
    const { data } = await axios.put(
      h.baseUrl('payment/' + id),
      { id, invoice_id, method, payment_date, payment_time, ref_number, bank_name, account_number, total_payment, status, mode },
    );
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(
      h.baseUrl('payment/' + id),
    );
    return data;
  },
};

export default payment;
