import MainLayout from "app/layouts/main";
import Table from "app/components/atoms/table";
import { Select } from "app/components/atoms/select";
import Typography from "app/components/atoms/typography";
import { IconButton, BadgeButton } from "app/components/atoms/button";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Badge } from "app/components/atoms/badge";

function CreditHistoryPage() {
  return (
    <MainLayout
      activeSidebarNavigation="customer"
      activeSidebarChild="credit-history"
      breadcrumbItems={[
        { label: "Customer", href: "#" },
        { label: "Credit History", href: "#" },
      ]}
      pageTitle="Credit History"
      headingButtons={[
        {
          label: "Tambah Kredit",
          type: "menuButton",
          items: [
            {
              label: "Meh 2",
              href: "#",
            },
          ],
        },
      ]}
    >
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <Table.SearchBarItemWrapper>
              <Select
                placeholder="Customer"
                options={[
                  {
                    value: 1,
                    label: "Indarto",
                  },
                  {
                    value: 2,
                    label: "Odik",
                  },
                ]}
              />
            </Table.SearchBarItemWrapper>
          </Table.SearchBarWrapper>
          <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper>
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Tanggal</Table.Heading>
              <Table.Heading>Customer</Table.Heading>
              <Table.Heading>Total</Table.Heading>
              <Table.Heading>Salesperson</Table.Heading>
              <Table.Heading>Deskripsi</Table.Heading>
              <Table.Heading>Tipe</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            <Table.BodyRow>
              <Table.Cell>
                <Typography.Date date="2023-02-12" />
              </Table.Cell>
              <Table.Cell>Indarto</Table.Cell>
              <Table.Cell fontWeight="semibold">
                <span className="text-danger-500">
                  <Typography.Currency number={75000} />
                </span>
              </Table.Cell>
              <Table.Cell>Admin</Table.Cell>
              <Table.Cell>
                <Badge color="red">Refund Kredit</Badge>
              </Table.Cell>
              <Table.Cell>
                <div className="flex space-x-2">
                  <BadgeButton>View</BadgeButton>
                  <div className="flex">
                    <IconButton icon={PencilSquareIcon} />
                    <IconButton icon={TrashIcon} />
                  </div>
                </div>
              </Table.Cell>
            </Table.BodyRow>
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default CreditHistoryPage;
