import axios from "../axios";
import h from "../helpers";

const salesOrder = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("invoice"), { params });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("invoice/" + id));
    return data;
  },
  store: async (customer_id, total_amount, is_use_credit, is_use_plafon, invoice_date, due_date, credit_amount, status, note, Orders) => {
    const { data } = await axios.post(
      h.baseUrl('invoice'),
      { customer_id, total_amount, is_use_credit, is_use_plafon, invoice_date, due_date, credit_amount, status, note, Orders },
    );
    return data;
  },
  update: async (id, customer_id, total_amount, is_use_credit, is_use_plafon, invoice_date, due_date, credit_amount, status, note, Orders) => {
    const { data } = await axios.put(
      h.baseUrl('invoice/' + id),
      { customer_id, total_amount, is_use_credit, is_use_plafon, invoice_date, due_date, credit_amount, status, note, Orders },
    );
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(
      h.baseUrl('invoice/' + id),
    );
    return data;
  },
};

export default salesOrder;
