import axios from "../axios";
import h from "../helpers";

const dailyMenu = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("product"), { params });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("product/" + id));
    return data;
  },
  store: async (
    product_category_id,
    name,
    price,
    notes,
    delivery_date,
    m_variant_id
  ) => {
    const { data } = await axios.post(h.baseUrl("product"), {
      product_category_id,
      name,
      price,
      notes,
      delivery_date,
      m_variant_id,
      is_active: true,
    });
    return data;
  },
  update: async (
    id,
    product_category_id,
    name,
    price,
    notes,
    delivery_date,
    m_variant_id
  ) => {
    const { data } = await axios.put(h.baseUrl("product/" + id), {
      product_category_id,
      name,
      price,
      notes,
      delivery_date,
      m_variant_id,
      is_active: true,
    });
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(h.baseUrl("product/" + id));
    return data;
  },
};

export default dailyMenu;
