import { useSelector } from "react-redux";
import { useState } from "react";
import clsx from "clsx";
import MainLayoutAuthGuard from "./authGuard";
import MainLayoutSidebar from "./sidebar";
import MainLayoutNavbar from "./navbar";
import { BodyClassNameOverrider } from "app/components/atoms/overrider";
import MainLayoutHeading from "./heading";

function MainLayout({
  activeSidebarNavigation, activeSidebarChild, breadcrumbItems, pageTitle,
  headingButtons, children
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const darkMode = useSelector(state => state.darkMode.value);

  return (
    <div>
      <MainLayoutAuthGuard>
        <MainLayoutSidebar
          open={sidebarOpen}
          onSidebarOpen={(e) => setSidebarOpen(true)}
          onSidebarClose={(e) => setSidebarOpen(false)}
          activeNavigation={activeSidebarNavigation}
          activeChild={activeSidebarChild}
        />
        <MainLayoutNavbar onSidebarButtonClick={() => setSidebarOpen(true)} />
        <BodyClassNameOverrider
          className={clsx(
            "overflow-clip bg-white dark:bg-gray-800",
            darkMode ? "dark" : ""
          )}
        >
          <div className="flex bg-white dark:bg-gray-800">
            <div
              className="fixed inset-0 z-10 hidden bg-gray-800 opacity-50"
              id="sidebarBackdrop"
            ></div>
            <div
              id="main-content"
              className="relative z-20 flex w-full flex-col overflow-visible print:h-full md:ml-14 print:md:ml-0 xl:ml-52 main-content-max-height"
            >
              <MainLayoutHeading
                breadcrumbItems={breadcrumbItems}
                pageTitle={pageTitle}
                buttons={headingButtons}
              />
              {children}
            </div>
          </div>
        </BodyClassNameOverrider>
      </MainLayoutAuthGuard>
    </div>
  );
}

export default MainLayout;
