import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import { useCollector, usePaginatedCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import Table from "app/components/atoms/table";
import { Input, SearchInput } from "app/components/atoms/input";
import { Select, SelectSearch } from "app/components/atoms/select";
import Typography from "app/components/atoms/typography";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  ServerSidePaginationButtons,
  IconButton,
} from "app/components/atoms/button";
import { FormHeader } from "app/components/atoms/layout";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Sales", href: "#" },
  { label: "Order", href: "#" },
];

function getTotal(orderItems) {
  return orderItems
    ?.map((orderItem) => {
      return (
        orderItem.qty * +orderItem.unit_price +
        orderItem?.variants
          ?.map((item) => +item.price)
          .reduce((partialSum, a) => partialSum + a, 0)
      );
    })
    .reduce((partialSum, a) => partialSum + a, 0);
}

function getMenuQty(orderItems, menu) {
  var qty = 0;
  orderItems?.map((orderItem) => {
    if (orderItem?.Product?.ProductCategory?.name === menu) {
      qty += orderItem.qty;
    }
  });
  return qty;
}

function SalesOrderPage() {
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();

  const [params, setParams] = useState({
    search: "",
    delivery_date: null,
    customer_id: null,
    status: null,
    is_valid_order: null,
    page: 1,
    limit: 50,
  });

  const [
    salesOrders,
    setSalesOrders,
    salesOrdersLoader,
    collectSalesOrders,
    salesOrderPaginationProps
  ] = usePaginatedCollector({ modelName: "salesOrder", params: params });

  const [customers, setCustomers, customersLoader, collectCustomers] =
    useCollector({ modelName: "customer" });

  return (
    <MainLayout
      activeSidebarNavigation="order"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Order"
      headingButtons={[
        {
          label: "Tambah Order",
          type: "routerButton",
          to: "/sales_order/create",
        },
      ]}
    >
      <AppModals
        items={["salesOrder.delete"]}
        onSuccess={{
          "salesOrder.delete": () => collectSalesOrders(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <SelectSearch
                label="Customer"
                placeholder="Customer"
                options={customers
                  ?.sort(function (a, b) {
                    if (
                      a.first_name?.toLowerCase() < b.first_name?.toLowerCase()
                    )
                      return -1;
                    if (
                      a.first_name?.toLowerCase() > b.first_name?.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  .map((customer) => ({
                    value: customer.id,
                    label:
                      customer.first_name +
                      " " +
                      customer.middle_name +
                      " " +
                      customer.last_name +
                      (customer.nick_name
                        ? " (" + customer.nick_name + ")"
                        : ""),
                  }))}
                value={params.customer_id}
                onChange={(e) =>
                  setParams({ ...params, customer_id: e.target.value })
                }
              />
              {/* <SearchInput
                label="Search"
                placeholder="Search Customer"
                value={params.search}
                onChange={(e) =>
                  setParams({ ...params, search: e.target.value })
                }
              /> */}
              <Input
                label="Tanggal Kirim"
                placeholder="Tanggal Kirim"
                type="date"
                value={params.delivery_date}
                onChange={(e) =>
                  setParams({ ...params, delivery_date: e.target.value })
                }
              />
              {/* <Select
                label="Status"
                placeholder="Quotation"
                value={params.status}
                onChange={(e) =>
                  setParams({ ...params, status: e.target.value })
                }
                options={[
                  {
                    value: 1,
                    label: "Completed",
                  },
                  {
                    value: 0,
                    label: "Partial",
                  },
                ]}
              /> */}
              {/* <SelectSearch
                label="Customer"
                placeholder="Customer"
                options={customers
                  ?.sort(function (a, b) {
                    if (
                      a.first_name?.toLowerCase() < b.first_name?.toLowerCase()
                    )
                      return -1;
                    if (
                      a.first_name?.toLowerCase() > b.first_name?.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  .map((customer) => ({
                    value: customer.id,
                    label:
                      customer.first_name +
                      " " +
                      customer.middle_name +
                      " " +
                      customer.last_name +
                      (customer.nick_name
                        ? " (" + customer.nick_name + ")"
                        : ""),
                  }))}
                value={params.customer_id}
                onChange={(e) =>
                  setParams({ ...params, customer_id: e.target.value })
                }
              /> */}
              <Select
                label="Order Valid/Invalid"
                placeholder=""
                options={[
                  {
                    value: null,
                    label: "Semua",
                  },
                  {
                    value: true,
                    label: "Valid",
                  },
                  {
                    value: false,
                    label: "Invalid",
                  },
                ]}
                value={params.is_valid_order}
                onChange={(e) =>
                  setParams({ ...params, is_valid_order: e.target.value })
                }
              />
            </FormHeader>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>No.</Table.Heading>
              <Table.Heading>Customer</Table.Heading>
              <Table.Heading>Tanggal Kirim</Table.Heading>
              <Table.Heading>Menu</Table.Heading>
              <Table.Heading align="right">Total</Table.Heading>
              <Table.Heading className="hidden md:table-cell">
                Sales
              </Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading></Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {salesOrders?.map((salesOrder, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>
                  <p>{salesOrder.order_code}</p>
                  <Typography.Date size="xs" date={salesOrder.createdAt} />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    {salesOrder.Customer?.first_name}{" "}
                    {salesOrder.Customer?.middle_name}{" "}
                    {salesOrder.Customer?.last_name}
                    {salesOrder.delivery_to !== salesOrder.customer_id &&
                      salesOrder.Customer?.nick_name !== null &&
                      salesOrder.Customer?.nick_name !== "" &&
                      "(" + salesOrder.Customer?.nick_name + ")"}
                  </p>
                  {salesOrder.delivery_to !== salesOrder.customer_id && (
                    <p>
                      <span className="text-xs font-light text-gray-400">
                        dari:
                      </span>{" "}
                      {customers.find((obj) => {
                        return obj.id === salesOrder.customer_id;
                      })?.first_name +
                        " " +
                        customers.find((obj) => {
                          return obj.id === salesOrder.customer_id;
                        })?.nick_name}
                    </p>
                  )}

                  {salesOrder.delivery_to === salesOrder.customer_id && (
                    <p className="text-xs font-light text-gray-500">
                      {salesOrder.Customer?.nick_name}
                      {salesOrder.Customer?.deletedAt !== null ? (
                        <span className="text-danger-400 font-light text-xs">
                          {" "}
                          [deleted customer]
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Typography.Date date={salesOrder.delivery_date} />

                  <p className="text-xs font-light text-gray-400">
                    {salesOrder.delivery_time === 0 ? (
                      <Badge color="green">Kirim Pagi</Badge>
                    ) : (
                      "Default"
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex gap-2">
                    <p className="text-xs font-light text-gray-400">
                      A:{" "}
                      <span className="text-sm font-medium text-gray-700">
                        {getMenuQty(salesOrder.OrderItems, "A")}
                      </span>
                    </p>
                    <p className="text-xs font-light text-gray-400">
                      B:{" "}
                      <span className="text-sm font-medium text-gray-700">
                        {getMenuQty(salesOrder.OrderItems, "B")}
                      </span>
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <p className="text-right">
                    <Typography.Currency
                      number={
                        Number(salesOrder.total_order) +
                        Number(salesOrder.delivery_fee) -
                        Number(salesOrder.delivery_fee_discount)
                      }
                    />
                  </p>
                </Table.Cell>
                <Table.Cell className="hidden lg:table-cell">
                  <p className="text-left">
                    {salesOrder?.userCreateOrder?.username}
                  </p>
                  {/* <p className="text-center text-danger-500 text-xs font-extralight">
                    (hardcoded)
                  </p> */}
                </Table.Cell>
                <Table.Cell>
                  <p className="text-xs font-light text-gray-400">
                    {salesOrder.is_valid_order ? (
                      <Badge color="green">Valid</Badge>
                    ) : (
                      <Badge color="red">
                        {DateTime.fromISO(salesOrder.delivery_date)
                          .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                          .diff(
                            DateTime.now().set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                            ["days"]
                          )
                          .toObject().days < 0
                          ? "Invalid / Expired"
                          : "Invalid"}
                      </Badge>
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  {salesOrder.Invoice != null ? (
                    <BadgeButton
                      onClick={(e) =>
                        navigate("/invoice/detail/" + salesOrder?.Invoice?.id)
                      }
                    >
                      Invoice
                    </BadgeButton>
                  ) : (
                    <Badge
                      color={salesOrder.Invoice != null ? "green" : "yellow"}
                    >
                      {salesOrder.Invoice != null ? "Invoice" : "Quotation"}
                    </Badge>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex space-x-2">
                    <div className="flex">
                      <IconButton
                        icon={PencilSquareIcon}
                        onClick={(e) => {
                          navigate("/sales_order/update/" + salesOrder.id);
                        }}
                      />
                      <IconButton
                        icon={TrashIcon}
                        onClick={(e) => {
                          setPayloads("salesOrder.delete", {
                            id: salesOrder.id,
                          });
                          openModal("salesOrder.delete");
                        }}
                      />
                    </div>
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={salesOrderPaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default SalesOrderPage;
