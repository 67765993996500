import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { BadgeButton } from "app/components/atoms/button";
import BadgeLink from "app/components/atoms/button/badgeLink";
import { Card } from "app/components/atoms/card";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { SearchInputMolecule } from "app/components/molecules/input";
import { SelectMolecule } from "app/components/molecules/select";
import { useFetcher } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

function DailyOrderReportPage() {
  const navigate = useNavigate();

  const today = () => {
    const now = DateTime.now();
    return now.toISODate();
  };

  const { openModal, setPayloads } = useApplicationModals();

  const [queryParams, setQueryParams] = useState({
    delivery_date: today(),
    cust_name: "",
    is_valid_order: true,
  });

  const [report] = useFetcher({
    modelName: "report",
    methodName: "show",
    payload: { id: queryParams },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const convertStatus = (int) => {
    switch (int) {
      case 1:
        return "Unpaid";
        break;
      case 2:
        return "Partial";
        break;
      case 3:
        return "Lunas";
        break;
      case 3:
        return "Cancel";
        break;

      default:
        break;
    }
  };
  return (
    <MainLayout
      activeSidebarNavigation="report"
      activeSidebarChild="daily-order"
      breadcrumbItems={[
        { label: "Report", href: "#" },
        { label: "Order Harian", href: "#" },
      ]}
      pageTitle="Order Harian"
      headingButtons={[
        {
          label: "Print Report",
          type: "menuButton",
          onClick: handlePrint,
          items: [
            {
              label: "Bulk Print Surat Jalan",
              onClick: (e) => {
                setPayloads("deliverySlip.create", {
                  data: report?.daily_order,
                });
                openModal("deliverySlip.create");
              },
            },
            {
              label: "Bulk Print Stiker",
              onClick: (e) => {
                setPayloads("menuSticker.create", {
                  data: report?.daily_order,
                });
                openModal("menuSticker.create");
              },
            },
            {
              label: "Rekap Surat Jalan",
              onClick: (e) => {
                setPayloads("deliveryReport.create", {
                  data: report?.daily_order,
                });
                openModal("deliveryReport.create");
              },
            },
          ],
        },
      ]}
    >
      <AppModals
        items={["deliverySlip.create"]}
        onSuccess={{
          "deliverySlip.create": () => fetch(),
        }}
      />
      <AppModals
        items={["menuSticker.create"]}
        onSuccess={{
          "menuSticker.create": () => fetch(),
        }}
      />
      <AppModals
        items={["deliveryReport.create"]}
        onSuccess={{
          "deliveryReport.create": () => fetch(),
        }}
      />
      <div ref={componentRef}>
        <Table.Container>
          <Table.SearchBarWrapper>
            <FormHeader>
              <InputMolecule
                label="Tanggal Kirim"
                type="date"
                value={today()}
                onChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    delivery_date: e.target.value,
                  });
                }}
              />
              <SearchInputMolecule
                label="Customer"
                placeholder="Search Customer"
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    setQueryParams({
                      ...queryParams,
                      cust_name: e.target.value,
                    });
                  }
                }}
              />
              <SelectMolecule
                label="Status Invoice"
                placeholder="Pilih Status"
                options={[
                  {
                    value: "",
                    label: "Semua Status",
                  },
                  {
                    value: "1",
                    label: "Unpaid",
                  },
                  {
                    value: "2",
                    label: "Partial",
                  },
                  {
                    value: "3",
                    label: "Lunas",
                  },
                  // {
                  //   value: 4,
                  //   label: "Cancel",
                  // },
                ]}
                onChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    payment_status: e.target.value,
                  });
                }}
                absolute={false}
              />
              <SelectMolecule
                label="Order Valid/Invalid"
                placeholder=""
                options={[
                  {
                    value: null,
                    label: "Semua",
                  },
                  {
                    value: true,
                    label: "Valid",
                  },
                  {
                    value: "false",
                    label: "Invalid",
                  },
                ]}
                absolute={false}
                value={queryParams.is_valid_order}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    is_valid_order: e.target.value,
                  })
                }
              />
            </FormHeader>
          </Table.SearchBarWrapper>
          <Table.SummaryWrapper>
            {report?.cal_order?.length > 0 &&
              report?.cal_order.map((item, index) => (
                <Card key={index}>
                  <p className="font-light text-sm">
                    {item.categories}:{" "}
                    <span className="font-medium">{item.name}</span> @Rp
                    <Typography.Currency
                      number={item.price}
                    ></Typography.Currency>
                    :{" "}
                    <span className="text-base font-medium">
                      {item.total_product}
                    </span>{" "}
                    pax
                  </p>
                </Card>
              ))}
            {/* <Table.UtilitiesWrapper toggleMargin={false}>
            <Table.UtilitiesButtonWrapper>
              <IconButton icon={PrinterIcon} />
            </Table.UtilitiesButtonWrapper>
          </Table.UtilitiesWrapper> */}
          </Table.SummaryWrapper>
          <Table.Wrapper asIndex={true}>
            <Table.Header>
              <Table.HeaderRow>
                <Table.Heading>No.</Table.Heading>
                <Table.Heading>Customer</Table.Heading>
                {/* <Table.Heading>Use Plafon</Table.Heading> */}
                <Table.Heading>Tanggal Kirim</Table.Heading>
                <Table.Heading align="right">Total</Table.Heading>
                <Table.Heading>Salesperson</Table.Heading>
                <Table.Heading>Invoice</Table.Heading>
                <Table.Heading>Order</Table.Heading>
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {report?.daily_order?.length > 0 &&
                report?.daily_order.map((item, index) => (
                  <Table.BodyRow key={index}>
                    <Table.Cell>
                      <BadgeLink href={"/sales_order/update/" + item.order_id}>
                        {item.order_code}
                      </BadgeLink>
                      <p className="text-left">
                        <Typography.Date size="xs" date={item.order_date} />
                      </p>
                    </Table.Cell>
                    <Table.Cell>
                      <div>{item.cust_name}</div>
                      <div className="text-sm font-light text-gray-500">
                        {item.nick_name}
                      </div>
                      {/* <div>Plafon: {item.plafon}</div>
                    <div>Credit: {item.credit}</div> */}
                    </Table.Cell>
                    {/* <Table.Cell>{item.is_use_plafon ? "Yes" : "No"}</Table.Cell> */}
                    <Table.Cell>
                      <Typography.Date date={item.delivery_date} />
                    </Table.Cell>
                    <Table.Cell align="right">
                      <Typography.Currency
                        number={
                          parseInt(item.total_order) +
                          parseInt(item.delivery_fee) -
                          parseInt(item.delivery_fee_discount)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>{item.sales ? item.sales : "-"}</Table.Cell>
                    <Table.Cell>
                      {item.status === 3 ? (
                        <Badge color="green">
                          {convertStatus(item.status)}
                        </Badge>
                      ) : (
                        <></>
                      )}
                      {item.status === 2 ? (
                        <Badge color="yellow">
                          {convertStatus(item.status)}
                        </Badge>
                      ) : (
                        <></>
                      )}
                      {item.status === 1 ? (
                        <Badge color="red">{convertStatus(item.status)}</Badge>
                      ) : (
                        <></>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {item.is_valid_order ? (
                        <Badge color="green">Valid</Badge>
                      ) : (
                        <Badge color="red">Invalid</Badge>
                      )}
                    </Table.Cell>
                    {/* <Table.Cell>
                    <div className="flex space-x-2">
                      <BadgeButton>Invoice</BadgeButton>
                      <div className="flex">
                        <IconButton icon={PencilSquareIcon} />
                        <IconButton icon={TrashIcon} />
                      </div>
                    </div>
                  </Table.Cell> */}
                  </Table.BodyRow>
                ))}
              {report?.daily_order?.length > 0 && (
                <Table.TotalsRow>
                  <Table.Cell background={100}></Table.Cell>
                  <Table.Cell background={100}>
                    <strong>TOTALS</strong>
                  </Table.Cell>
                  <Table.Cell background={100}></Table.Cell>
                  <Table.Cell background={100} align="right">
                    <Typography.Currency
                      number={report?.daily_order
                        ?.map(
                          (item) =>
                            +(
                              parseInt(item.total_order) +
                              parseInt(item.delivery_fee) -
                              parseInt(item.delivery_fee_discount)
                            )
                        )
                        .reduce((partialSum, a) => partialSum + a, 0)}
                    />
                  </Table.Cell>
                  <Table.Cell background={100}></Table.Cell>
                  <Table.Cell background={100}></Table.Cell>
                  <Table.Cell background={100}></Table.Cell>
                </Table.TotalsRow>
              )}
            </Table.Body>
          </Table.Wrapper>
        </Table.Container>
      </div>
    </MainLayout>
  );
}

export default DailyOrderReportPage;
