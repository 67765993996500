import { useState, useEffect } from 'react';
import { InputLabel } from 'app/components/atoms/form';
import { SearchInput } from 'app/components/atoms/input';

function SearchInputMolecule({ label, name, placeholder, value = '', onChange = () => {}, onBlur = () => {} }) {
  const [ localValue, setLocalValue ] = useState(value);
  const localOnChange = event => {
    setLocalValue(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <SearchInput
        name={name}
        placeholder={placeholder}
        onChange={localOnChange}
        onBlur={onBlur}
        value={localValue}
      />
    </div>
  );
}

export default SearchInputMolecule;
