import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showStates: {
    'variant.create': false,
    'variant.update': false,
    'variant.delete': false,
    'menuCategory.delete': false,
    'menuCategory.delete': false,
    'menuCategory.delete': false,
    'dailyMenu.delete': false,
    'dailyMenu.delete': false,
    'dailyMenu.delete': false,
    'shipmentCostCategory.delete': false,
    'customer.create': false,
    'customer.delete': false,
    'salesOrder.delete': false,
    'invoice.delete': false,
    'payment.create': false,
    'payment.update': false,
    'payment.delete': false,
    'payment.confirm': false,
  },
  payloads: {
    'variant.create': {},
    'variant.update': {},
    'variant.delete': {},
    'menuCategory.create': {},
    'menuCategory.update': {},
    'menuCategory.delete': {},
    'dailyMenu.create': {},
    'dailyMenu.update': {},
    'dailyMenu.delete': {},
    'shipmentCostCategory.create': {},
    'shipmentCostCategory.update': {},
    'shipmentCostCategory.delete': {},
    'customer.create': {},
    'customer.delete': {},
    'salesOrder.delete': {},
    'invoice.delete': {},
    'payment.create': {},
    'payment.update': {},
    'payment.delete': {},
    'payment.confirm': {},
  }
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    open: (state, { payload }) => {
      state.showStates[payload] = true;
    },
    close: (state, { payload }) => {
      state.showStates[payload] = false;
    },
    fill: (state, { payload: { key, payload } }) => {
      state.payloads[key] = payload;
    },
    clear: (state, { payload: { key } }) => {
      state.payloads[key] = {};
    },
  },
});

export const { open, close, fill, clear } = modalsSlice.actions;

export default modalsSlice.reducer;
