import axios from "../axios";
import h from "../helpers";

const report = {
  index: async () => {
    const { data } = await axios.get(h.baseUrl("report"));
    return data;
  },
  show: async (filter) => {
    let arr = [];
    let queryParams = "";

    if (filter && filter != {}) {
      const filterObj = Object.entries(filter);
      for (let i in filterObj) {
        if (filterObj[i][1] && filterObj[i][1] != "") {
          arr.push(`${filterObj[i][0]}=${filterObj[i][1]}`);
        }
      }
    }

    if (arr.length > 0) {
      for (let i in arr) {
        if (i == 0) {
          queryParams += `?${arr[i]}`;
        } else {
          queryParams += `&${arr[i]}`;
        }
      }
    }
    const { data } = await axios.get(h.baseUrl("report/" + queryParams));
    return data;
  },
};

export default report;
