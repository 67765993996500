import clsx from 'clsx';
import tu from 'lib/tu';
import str from 'lib/str';

function Currency({ number, fontSize = "sm", color = "", align = "" }) {
  return (
    <span
      className={clsx(
        "font-semibold",
        tu.fontSize(fontSize),
        color === "red"
          ? "text-danger-500"
          : color === "green"
          ? "text-success-500"
          : "",
        align === "right" ? "text-right" : ""
      )}
    >
      {Number.isNaN(+number) ? "" : str.currency(number, "id-ID", "IDR")}
    </span>
  );
}

export default Currency;
