import axios from "../axios";
import h from "../helpers";

const customer = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("customer"), { params: { ...params } });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("customer/" + id));
    return data;
  },
  store: async (first_name, last_name, middle_name, nick_name, email, phone_number, credit, plafon, Addresses) => {
    const { data } = await axios.post(
      h.baseUrl('customer'),
      { 
        first_name, last_name, middle_name, nick_name, email, phone_number, credit, plafon, Addresses
      },
    );
    return data;
  },
  update: async (id, first_name, last_name, middle_name, nick_name, email, phone_number, credit, plafon, Addresses) => {
    const { data } = await axios.put(
      h.baseUrl('customer/' + id),
      { 
        first_name, last_name, middle_name, nick_name, email, phone_number, credit, plafon, Addresses
      },
    );
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(
      h.baseUrl('customer/' + id),
    );
    return data;
  },
};

export default customer;
