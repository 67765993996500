import { PrinterIcon } from "@heroicons/react/24/solid";
import { Badge } from "app/components/atoms/badge";
import { BadgeButton, IconButton } from "app/components/atoms/button";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { DateRangeInputMolecule } from "app/components/molecules/input";
import { useFetcher } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { DateTime } from "luxon";
import { useApplicationModals } from "app/common/hooks";
import AppModals from "app/modals";
import { useNavigate } from "react-router-dom";
import BadgeLink from "app/components/atoms/button/badgeLink";
import { isNumber } from "underscore";

function CreditRecapReportPage() {
  const navigate = useNavigate();

  const today = () => {
    const now = DateTime.now();
    return now.toISODate();
  };

  const [queryParams, setQueryParams] = useState({
    credit_recap: 1,
    payment_start: "",
    payment_end: "",
    payment_status: "",
    delivery_start: today(),
    delivery_end: today(),
  });

  const [report] = useFetcher({
    modelName: "report",
    methodName: "show",
    payload: { id: queryParams },
  });

  const groupedData =
    report?.length > 0 &&
    report
      ?.filter(
        (item) =>
          Number(item.total_amount) -
            Number(item.credit_amount) -
            Number(item.total_payment) !==
          0
      )
      .reduce((groups, item) => {
        const cust_name =
          item.nick_name?.length > 0
            ? item.customer_name + " (" + item.nick_name + ")"
            : item.customer_name;
        const group = groups[cust_name] || [];
        group.push(item);
        groups[cust_name] = group;
        return groups;
      }, {});

  // console.log("data:", report);
  // console.log("grouped data: ", groupedData);

  const componentRef = useRef();

  const { openModal, setPayloads } = useApplicationModals();

  function TableRows() {
    var el = [];

    Object.keys(groupedData).length > 0 ? (
      Object.keys(groupedData).forEach((key, index) => {
        el.push(
          <Table.BodyRow key={index}>
            {/* {console.log("key:", key)}
            {console.log("item:", groupedData[key])} */}
            <Table.Cell>{key}</Table.Cell>
            <Table.Cell>
              <div className="flex flex-wrap gap-1">
                {groupedData[key].map((item, idx) => (
                  <BadgeLink
                    color={
                      item?.status === 3
                        ? "green"
                        : item?.status === 2
                        ? "yellow"
                        : "red"
                    }
                    href={"/invoice/detail/" + item.invoice_id}
                  >
                    {item.inv_code}
                  </BadgeLink>
                ))}
              </div>
            </Table.Cell>
            <Table.Cell fontWeight="semibold" align="right">
              <Typography.Currency
                number={groupedData[key]
                  .map((item) => Number(item.total_amount))
                  .reduce((partialSum, a) => partialSum + a, 0)}
              />
            </Table.Cell>
            <Table.Cell fontWeight="semibold" align="right">
              <Typography.Currency
                number={groupedData[key]
                  .map((item) => Number(item.total_payment))
                  .reduce((partialSum, a) => partialSum + a, 0)}
              />
            </Table.Cell>
            <Table.Cell fontWeight="semibold" align="right">
              <Typography.Currency
                number={groupedData[key]
                  .map((item) => Number(item.credit_amount))
                  .reduce((partialSum, a) => partialSum + a, 0)}
              />
            </Table.Cell>
            <Table.Cell align="right">
              <Typography.Currency
                color={
                  groupedData[key]
                    .map((item) => Number(item.total_amount))
                    .reduce((partialSum, a) => partialSum + a, 0) >
                  groupedData[key]
                    .map((item) => Number(item.total_payment))
                    .reduce((partialSum, a) => partialSum + a, 0) +
                    groupedData[key]
                      .map((item) => Number(item.credit_amount))
                      .reduce((partialSum, a) => partialSum + a, 0)
                    ? "red"
                    : "green"
                }
                number={
                  groupedData[key]
                    .map((item) => Number(item.total_payment))
                    .reduce((partialSum, a) => partialSum + a, 0) +
                  groupedData[key]
                    .map((item) => Number(item.credit_amount))
                    .reduce((partialSum, a) => partialSum + a, 0) -
                  groupedData[key]
                    .map((item) => Number(item.total_amount))
                    .reduce((partialSum, a) => partialSum + a, 0)
                }
              />
            </Table.Cell>
          </Table.BodyRow>
        );
      })
    ) : (
      <></>
    );
    // console.log("return el:", el);
    return el;
  }

  function TotalsRow() {
    const totalPayment =
      report?.length > 0 &&
      report
        ?.filter(
          (item) =>
            Number(item.total_amount) -
              Number(item.total_payment) -
              Number(item.credit_amount) !==
            0
        )
        .map((item) => Number(item.total_payment))
        .reduce((partialSum, a) => partialSum + a, 0);

    const totalInvoice =
      report?.length > 0 &&
      report
        ?.filter(
          (item) =>
            Number(item.total_amount) -
              Number(item.total_payment) -
              Number(item.credit_amount) !==
            0
        )
        .map((item) =>
          Number(item.total_amount) > 0 ? Number(item.total_amount) : 0
        )
        .reduce((partialSum, a) => partialSum + a, 0);

    const totalCredit =
      report?.length > 0 &&
      report
        ?.filter(
          (item) =>
            Number(item.total_amount) -
              Number(item.total_payment) -
              Number(item.credit_amount) !==
            0
        )
        .map((item) => Number(item.credit_amount))
        .reduce((partialSum, a) => partialSum + a, 0);

    return (
      <Table.TotalsRow>
        <Table.Cell background={100}>
          <strong>TOTALS</strong>
        </Table.Cell>
        <Table.Cell background={100}></Table.Cell>
        <Table.Cell align="right" background={100}>
          <Typography.Currency number={totalInvoice} />
        </Table.Cell>
        <Table.Cell align="right" background={100}>
          <Typography.Currency number={totalPayment} />
        </Table.Cell>
        <Table.Cell align="right" background={100}>
          <Typography.Currency number={totalCredit} />
        </Table.Cell>
        <Table.Cell align="right" background={100}>
          <Typography.Currency
            color={
              totalPayment + totalCredit - totalInvoice < 0 ? "red" : "green"
            }
            number={totalPayment + totalCredit - totalInvoice}
          />
        </Table.Cell>
      </Table.TotalsRow>
    );
  }

  const convertStatus = (int) => {
    switch (int) {
      case 1:
        return "Unpaid";
        break;
      case 2:
        return "Partial";
        break;
      case 3:
        return "Lunas";
        break;
      case 3:
        return "Cancel";
        break;

      default:
        break;
    }
  };

  const TableContent = (
    <>
      <Table.Header>
        <Table.HeaderRow>
          <Table.Heading>Customer</Table.Heading>
          <Table.Heading>INVOICE</Table.Heading>
          <Table.Heading align="right">Total Invoice</Table.Heading>
          <Table.Heading align="right">Total Pembayaran</Table.Heading>
          <Table.Heading align="right">Total Kredit</Table.Heading>
          <Table.Heading align="right">Kurang / Lebih</Table.Heading>
        </Table.HeaderRow>
      </Table.Header>
      <Table.Body>
        {/* {console.log("grouped data keys:", Object.keys(groupedData))} */}
        {TableRows()}
        {TotalsRow()}
      </Table.Body>
    </>
  );

  return (
    <MainLayout
      activeSidebarNavigation="report"
      activeSidebarChild="credit-recap"
      breadcrumbItems={[
        { label: "Report", href: "#" },
        { label: "Kredit Rekap", href: "#" },
      ]}
      pageTitle="Report Kredit Rekap"
      headingButtons={[
        {
          label: "Print Report",
          type: "button",
          onClick: (e) => {
            setPayloads("report.view", {
              data: TableContent,
              title: "Report Kredit Rekap",
            });
            openModal("report.view");
          },
        },
      ]}
    >
      <AppModals
        items={["report.view"]}
        onSuccess={{
          "report.view": () => fetch(),
        }}
      />
      <div ref={componentRef}>
        <Table.Container>
          <Table.SearchBarWrapper>
            <FormHeader>
              <div className="col-span-2">
                <DateRangeInputMolecule
                  label="Tanggal Kirim"
                  from={queryParams?.delivery_start}
                  to={queryParams?.delivery_end}
                  onFromChange={(e) => {
                    setQueryParams({
                      ...queryParams,
                      delivery_start: e.target.value,
                    });
                  }}
                  onToChange={(e) => {
                    setQueryParams({
                      ...queryParams,
                      delivery_end: e.target.value,
                    });
                  }}
                />
              </div>
              {/* <div className="col-span-2">
                <DateRangeInputMolecule
                  label="Tanggal Pembayaran"
                  from={queryParams?.payment_start}
                  to={queryParams?.payment_end}
                  onFromChange={(e) => {
                    setQueryParams({
                      ...queryParams,
                      payment_start: e.target.value,
                    });
                  }}
                  onToChange={(e) => {
                    setQueryParams({
                      ...queryParams,
                      payment_end: e.target.value,
                    });
                  }}
                />
              </div> */}
              {/* <Table.SearchBarItemWrapper>
              <SelectMolecule
                label="Customer"
                placeholder="Customer"
                options={[
                  {
                    value: 1,
                    label: "Indarto",
                  },
                  {
                    value: 2,
                    label: "Odik",
                  },
                ]}
              />
            </Table.SearchBarItemWrapper> */}
            </FormHeader>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
          <Table.Wrapper asIndex={true}>{TableContent}</Table.Wrapper>
        </Table.Container>
      </div>
    </MainLayout>
  );
}

export default CreditRecapReportPage;
