import MainLayout from "app/layouts/main";
import Table from "app/components/atoms/table";
import { SearchInput } from "app/components/atoms/input";
import { IconButton } from "app/components/atoms/button";
import { PrinterIcon } from "@heroicons/react/24/solid";

function MainPage() {
  return (
    <MainLayout
      activeSidebarNavigation="dashboard"
      breadcrumbItems={[
        { label: "Sales", href: "#" },
        { label: "Order", href: "#" },
      ]}
      pageTitle="Sales Order"
      headingButtons={[
        {
          label: "Meh",
          type: "menuButton",
          items: [
            {
              label: "Meh 2",
              href: "#",
            },
          ],
        },
      ]}
    >
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <Table.SearchBarItemWrapper>
              <SearchInput />
            </Table.SearchBarItemWrapper>
          </Table.SearchBarWrapper>
          <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper>
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Meh</Table.Heading>
              <Table.Heading>Bleh</Table.Heading>
              <Table.Heading>Dawg</Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            <Table.BodyRow>
              <Table.Cell>Son of Meh</Table.Cell>
              <Table.Cell>Son of Bleh</Table.Cell>
              <Table.Cell>Son of Dawg</Table.Cell>
            </Table.BodyRow>
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default MainPage;
