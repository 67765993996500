import clsx from "clsx";

function badgeBgColor(color) {
  return {
    green:
      "border border-primary-700 dark:border-primary-300 text-primary-700 dark:text-primary-300 hover:bg-primary-700 hover:text-white dark:hover:bg-primary-300 dark:hover:text-primary-700",
    blue: "bg-info-100 dark:bg-info-800 text-info-800 dark:text-info-100",
    purple:
      "bg-primary-100 dark:bg-primary-800 text-primary-800 dark:text-primary-100",
    red: "bg-danger-100 dark:bg-danger-800 text-danger-800 dark:text-danger-100",
    yellow:
      "bg-warning-100 dark:bg-warning-800 text-warning-800 dark:text-warning-100",
    gray: "bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100",
  }[color];
}

function BadgeButton({
  children,
  color = "green",
  disabled = false,
  onClick = () => {},
  type = "button",
}) {
  return (
    <button
      className={clsx(
        "text-xs font-medium px-3 py-0.5 rounded-lg",
        disabled
          ? "border border-gray-400 dark:border-gray-500 text-gray-400 dark:text-gray-500"
          : badgeBgColor(color)
      )}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
}

export default BadgeButton;
