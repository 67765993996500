import { useApplicationModals } from "app/common/hooks";
import * as Yup from "yup";
import { useUpdateForm } from "app/integration/common/hooks";
import api from "app/integration/api";
import { useFetcher } from "app/integration/common/hooks";
import { useEffect } from "react";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { InputLabel } from "app/components/atoms/form";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import { ClosableCard } from "app/components/atoms/card";

function UpdateShipmentCostCategoryModal({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("shipmentCostCategory.update");

  const handleClose = () => closeModal("shipmentCostCategory.update");
  const handleSuccess = () => {
    closeModal("shipmentCostCategory.update");
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        name: "",
        price: 0,
        shipmentCostDiscounts: [],
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Nama harus diisi"),
        price: Yup.number().required("Harga Ongkir harus diisi"),
        shipmentCostDiscounts: Yup.array().of(
          Yup.object().shape({
            condition: Yup.number().required("Jumlah Box harus diisi"),
            discountPrice: Yup.number().required("Diskon harus diisi"),
          })
        ),
      }),
      onSubmit: async (values) => {
        await api.shipmentCostCategory.update(
          values.id,
          values.name,
          values.price,
          values.shipmentCostDiscounts.map((shipmentCostDiscount) => ({
            id: shipmentCostDiscount.id,
            condition: shipmentCostDiscount.condition,
            discount_price: shipmentCostDiscount.discountPrice,
          }))
        );
      },
      onSuccess: handleSuccess,
    });

  const [shipmentCostCategory, setShipmentCostCategory, loader, fetch] =
    useFetcher({ modelName: "shipmentCostCategory", payload });

  useEffect(() => {
    setInitialValues({
      id: shipmentCostCategory.id,
      name: shipmentCostCategory.name,
      price: shipmentCostCategory.price,
      shipmentCostDiscounts: shipmentCostCategory.DeliveryDiscounts?.map(
        (shipmentCostDiscount) => ({
          id: shipmentCostDiscount.id,
          condition: shipmentCostDiscount.condition,
          discountPrice: shipmentCostDiscount.discount_price,
        })
      ),
    });
  }, [shipmentCostCategory]);

  return (
    <ModalMolecule
      show={showStates["shipmentCostCategory.update"]}
      title="Ubah Kategori Ongkir"
      onClose={handleClose}
    >
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <InputMolecule
              label="Nama"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <InputMolecule
              label="Harga Ongkir"
              type="number"
              name="price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              errorMessage={errors.price && touched.price && errors.price}
            />
            <div>
              <hr className="my-5" />
            </div>
            <div>
              <InputLabel>Diskon Ongkir</InputLabel>
              <FieldArray name="shipmentCostDiscounts">
                {(arrayHelpers) => (
                  <div className="space-y-4">
                    {values?.shipmentCostDiscounts?.map(
                      (shipmentCostDiscount, shipmentCostDiscountKey) => (
                        <ClosableCard
                          key={shipmentCostDiscountKey}
                          onClose={() =>
                            arrayHelpers.remove(shipmentCostDiscountKey)
                          }
                        >
                          <div className="grid grid-cols-2 gap-4 p-2">
                            <InputMolecule
                              label="Jumlah Box"
                              type="number"
                              name={`shipmentCostDiscounts[${shipmentCostDiscountKey}].condition`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={shipmentCostDiscount.condition}
                              errorMessage={
                                getIn(
                                  errors,
                                  `shipmentCostDiscounts[${shipmentCostDiscountKey}].condition`
                                ) &&
                                getIn(
                                  touched,
                                  `shipmentCostDiscounts[${shipmentCostDiscountKey}].condition`
                                ) &&
                                getIn(
                                  errors,
                                  `shipmentCostDiscounts[${shipmentCostDiscountKey}].condition`
                                )
                              }
                            />
                            <InputMolecule
                              label="Diskon Ongkir"
                              type="number"
                              name={`shipmentCostDiscounts[${shipmentCostDiscountKey}].discountPrice`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={shipmentCostDiscount.discountPrice}
                              errorMessage={
                                getIn(
                                  errors,
                                  `shipmentCostDiscounts[${shipmentCostDiscountKey}].discountPrice`
                                ) &&
                                getIn(
                                  touched,
                                  `shipmentCostDiscounts[${shipmentCostDiscountKey}].discountPrice`
                                ) &&
                                getIn(
                                  errors,
                                  `shipmentCostDiscounts[${shipmentCostDiscountKey}].discountPrice`
                                )
                              }
                            />
                          </div>
                        </ClosableCard>
                      )
                    )}
                    <div>
                      <div className="my-4">
                        <DottedOutlineButton
                          type="button"
                          onClick={(e) =>
                            arrayHelpers.push({
                              condition: 0,
                              discountPrice: 0,
                            })
                          }
                        >
                          Tambah Diskon Ongkir
                        </DottedOutlineButton>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
            <div>
              <hr className="my-5" />
            </div>
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan Kategori Ongkir</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default UpdateShipmentCostCategoryModal;
