import { useState, useEffect, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

function Select({
  placeholder,
  name,
  options = [],
  value,
  onChange = () => {},
  absolute = true,
}) {
  const [localValue, setLocalValue] = useState(value);
  const localOnChange = (option) => {
    setLocalValue(option.value);
    onChange({
      target: {
        value: option.value,
        name,
      },
    });
  };

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    options?.length > 0 && (
      <Listbox
        value={options.find((option) => option.value === localValue) || null}
        onChange={localOnChange}
      >
        <div className="relative">
          <Listbox.Button className="group relative block w-full rounded-lg border border-gray-300 bg-white p-2.5 pr-11 text-gray-900 placeholder-gray-300 shadow-sm focus:border-primary-600 focus:outline-none disabled:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 dark:placeholder-gray-500 autofill:dark:bg-gray-800 dark:disabled:bg-gray-800 focus:ring-1 focus:ring-primary-600 text-base">
            <span className="block truncate text-left">
              {options.find((option) => option.value === localValue) ? (
                options.find((option) => option.value === localValue).label
              ) : (
                <Fragment>
                  {placeholder ? (
                    <span className="text-gray-300 dark:text-gray-500">
                      {placeholder}
                    </span>
                  ) : (
                    <span className="text-white dark:text-gray-900">.</span>
                  )}
                </Fragment>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="text-gray-500 h-5 w-5" />
            </span>
            <span className="fixed z-50 -mt-16 opacity-0 transition-opacity delay-300 duration-500 group-hover:opacity-100 bg-primary-900 text-white border rounded-sm px-4 py-2 text-xs text-left">
              {options.find((option) => option.value === localValue) ? (
                options.find((option) => option.value === localValue).label
              ) : (
                <></>
              )}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={clsx(
                absolute ? "absolute" : "fixed",
                "z-50 mt-1 max-h-60 min-w-[12rem] w-fit max-w-[90vw] overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              )}
            >
              {options.map((option, key) => (
                <Listbox.Option
                  key={key}
                  className={({ active }) =>
                    clsx(
                      "relative cursor-default select-none py-2 pr-4 pl-10",
                      active
                        ? "bg-primary-100 text-primary-900 dark:bg-primary-900 dark:text-primary-100"
                        : "text-gray-900 dark:text-white"
                    )
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <Fragment>
                      <span
                        className={clsx(
                          "block truncate",
                          selected ? "font-medium" : "font-normal"
                        )}
                      >
                        {option.label}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-500">
                          <CheckIcon className="h-5 w-5" />
                        </span>
                      )}
                    </Fragment>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    )
  );
}

export default Select;
