import axios from "../axios";
import h from "../helpers";

const variantCategory = {
  store: async (m_variant_id, name, is_multiple) => {
    const { data } = await axios.post(
      h.baseUrl('variant-category'),
      { m_variant_id, name, is_multiple },
    );
    return data;
  },
  update: async (id, m_variant_id, name, is_multiple) => {
    const { data } = await axios.put(
      h.baseUrl('variant-category/' + id),
      { m_variant_id, name, is_multiple },
    );
    return data;
  },
};

export default variantCategory;
