import { useApplicationModals } from "app/common/hooks";
import { useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from 'app/modals';
import Table from "app/components/atoms/table";
import { SearchInput } from "app/components/atoms/input";
import Typography from "app/components/atoms/typography";
import { IconButton } from "app/components/atoms/button";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";

const breadcrumbItems = [
  { label: "Settings", href: "#" },
  { label: "Kategori Ongkir", href: "#" },
];

function ShipmentCostCategoryPage() {
  const { openModal, setPayloads } = useApplicationModals();

  const [
    shipmentCostCategories,
    setShipmentCostCategories,
    loader,
    collect
  ] = useCollector({ modelName: 'shipmentCostCategory' });

  return (
    <MainLayout
      activeSidebarNavigation="settings"
      activeSidebarChild="shipment-cost-category"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Kategori Ongkir"
      headingButtons={[
        {
          label: "Tambah Kategori",
          type: "button",
          onClick: () => openModal("shipmentCostCategory.create"),
        },
      ]}
    >
      <AppModals
        items={[
          "shipmentCostCategory.create",
          "shipmentCostCategory.update",
          "shipmentCostCategory.delete",
        ]}
        onSuccess={{
          "shipmentCostCategory.create": () => collect(),
          "shipmentCostCategory.update": () => collect(),
          "shipmentCostCategory.delete": () => collect(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <Table.SearchBarItemWrapper>
              {/* <SearchInput placeholder="Search" /> */}
            </Table.SearchBarItemWrapper>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Nama</Table.Heading>
              <Table.Heading>Harga</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {shipmentCostCategories?.map((shipmentCostCategory, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>{shipmentCostCategory.name}</Table.Cell>
                <Table.Cell>
                  <Typography.Currency number={shipmentCostCategory.price} />
                </Table.Cell>
                <Table.Cell>
                  <div className="flex">
                    <IconButton
                      icon={PencilSquareIcon}
                      onClick={(e) => {
                        setPayloads("shipmentCostCategory.update", {
                          id: shipmentCostCategory.id,
                        });
                        openModal("shipmentCostCategory.update");
                      }}
                    />
                    <IconButton
                      icon={TrashIcon}
                      onClick={(e) => {
                        setPayloads("shipmentCostCategory.delete", {
                          id: shipmentCostCategory.id,
                        });
                        openModal("shipmentCostCategory.delete");
                      }}
                    />
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
            <Table.BodyRow></Table.BodyRow>
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default ShipmentCostCategoryPage;
