import { useApplicationModals } from "app/common/hooks";
import { useCreateForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { useCollector } from "app/integration/common/hooks";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { SelectMolecule } from "app/components/molecules/select";
import { Button } from "app/components/atoms/button";
import AppModals from ".";

function CreateDailyMenuModal({ onSuccess = () => {} }) {
  const { showStates, closeModal } = useApplicationModals();

  const handleClose = () => closeModal("dailyMenu.create");
  const handleSuccess = () => {
    closeModal("dailyMenu.create");
    onSuccess();
  };

  var isError = false;

  const { openModal, setPayloads } = useApplicationModals();

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      menuCategoryId: null,
      name: "",
      price: 0,
      notes: "",
      deliveryDate: "",
      variantId: null,
    },
    validationSchema: Yup.object().shape({
      menuCategoryId: Yup.number().required("Menu Harian harus diisi"),
      name: Yup.string().required("Nama Menu harus diisi"),
      price: Yup.number().required("Harga harus diisi"),
      deliveryDate: Yup.date().required("Tanggal harus diisi"),
      variantId: Yup.number().required("Kategori Menu harus diisi"),
    }),
    onSubmit: async (values) => {
      await api.dailyMenu
        .store(
          values.menuCategoryId,
          values.name,
          values.price,
          values.notes,
          values.deliveryDate,
          values.variantId
        )
        .catch((error) => {
          // console.log("eror: ", error);
          setPayloads("errorMessage.view", {
            title: "Gagal tambah Menu Harian",
            message: error?.response?.data?.message,
          });
          isError = true;
          openModal("errorMessage.view");
          // console.log("delete error: ", error?.response?.data?.message);
        });
    },
    onSuccess: () => {
      if (!isError) {
        handleSuccess();
      }
    },
  });

  const [menuCategories] = useCollector({ modelName: "menuCategory" });

  const [variants] = useCollector({ modelName: "variant" });

  return (
    <ModalMolecule
      show={showStates["dailyMenu.create"]}
      title="Tambah Menu Harian"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <InputMolecule
              label="Tanggal"
              type="date"
              name="deliveryDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.deliveryDate}
              errorMessage={
                errors.deliveryDate &&
                touched.deliveryDate &&
                errors.deliveryDate
              }
            />
            <SelectMolecule
              label="Menu"
              name="menuCategoryId"
              options={menuCategories
                ?.sort(function (a, b) {
                  if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
                  if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
                  return 0;
                })
                .map((menuCategory) => ({
                  value: menuCategory.id,
                  label: menuCategory.name,
                }))}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.menuCategoryId}
              errorMessage={
                errors.menuCategory &&
                touched.menuCategory &&
                errors.menuCategory
              }
            />
            <SelectMolecule
              label="Kategori Menu"
              name="variantId"
              options={variants.map((variant) => ({
                value: variant.id,
                label: variant.name,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.variantId}
              errorMessage={
                errors.variantId && touched.variantId && errors.variantId
              }
            />
            <InputMolecule
              label="Nama"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <InputMolecule
              label="Harga"
              type="number"
              name="price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              errorMessage={errors.price && touched.price && errors.price}
            />
            {/* <InputMolecule
              label="Catatan"
              type="text"
              name="notes"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.notes}
              errorMessage={errors.notes && touched.notes && errors.notes}
            /> */}
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Tambah Menu Harian</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateDailyMenuModal;
