import axios from "../axios";
import h from "../helpers";

const variant = {
  index: async () => {
    const { data } = await axios.get(h.baseUrl("master-variant"));
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("master-variant/" + id));
    return data;
  },
  store: async (name, desc, VariantCategories) => {
    const { data } = await axios.post(
      h.baseUrl('master-variant'),
      { name, desc, VariantCategories },
    );
    return data;
  },
  update: async (id, name, desc, VariantCategories) => {
    const { data } = await axios.put(
      h.baseUrl('master-variant/' + id),
      { name, desc, VariantCategories },
    );
    return data;
  },
  delete: async (id) => {
    const { data } = await axios.delete(
      h.baseUrl('master-variant/' + id),
    );
    return data;
  },
};

export default variant;
