
function InputLabel({ children }) {
  return (
    <label className="mb-1 block text-gray-400 dark:text-gray-500 text-xs uppercase whitespace-nowrap overflow-ellipsis overflow-clip">
      {children}
    </label>
  );
}

export default InputLabel;
