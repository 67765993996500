import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { Button } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { ModalMolecule } from "app/components/molecules/modal";
import LogoSL from "img/sl-logo-fit.PNG";
import { DateTime } from "luxon";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

function ViewReportModal({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("report.view");
  const report = payload?.data;
  const date_start = payload?.date_start;
  const date_end = payload?.date_end;
  const title = payload?.title;

  console.log("data: ", payload?.data);

  const handleClose = () => closeModal("report.view");
  const handleSuccess = () => {
    closeModal("report.view");
    onSuccess();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const convertStatus = (int) => {
    switch (int) {
      case 1:
        return "Unpaid";
        break;
      case 2:
        return "Partial";
        break;
      case 3:
        return "Lunas";
        break;
      case 3:
        return "Cancel";
        break;

      default:
        break;
    }
  };

  function mergeVariantsByName(variants) {
    const result = [];

    variants.forEach((variant) => {
      for (const key in variant) {
        const existingVariant = result.find((item) => item.menu === key);
        if (existingVariant) {
          existingVariant.qty += variant[key];
        } else {
          result.push({ menu: key, qty: variant[key] });
        }
      }
    });

    return result;
  }

  return (
    <ModalMolecule
      show={showStates["report.view"]}
      title={title}
      onClose={handleClose}
      size="xl"
    >
      <div
        ref={componentRef}
        className="space-y-1 text-sm border border-gray-100 print:shadow-none print:border-none min-h-[800px]"
      >
        <Table.Wrapper asIndex={false}>{report}</Table.Wrapper>
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Report
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default ViewReportModal;
