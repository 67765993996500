import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { Button } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import { ModalMolecule } from "app/components/molecules/modal";
import { useFetcher } from "app/integration/common/hooks";
import { standardDate, standardDay } from "lib/str";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import _ from "underscore";

function CreateDeliveryReportModal({ onSuccess = () => {} }) {
  const [queryParams, setQueryParams] = useState({
    delivery_slip: 1,
    order_ids: "",
  });

  const [report] = useFetcher({
    modelName: "report",
    methodName: "show",
    payload: { id: queryParams },
  });

  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("deliveryReport.create");

  const handleClose = () => closeModal("deliveryReport.create");
  const handleSuccess = () => {
    closeModal("deliveryReport.create");
    onSuccess();
  };

  useEffect(() => {
    if (payload?.data?.length > 0) {
      console.log("payload: ", payload.data);
      const order_ids = payload.data.map((item) => {
        return item.order_id;
      });

      setQueryParams({
        ...queryParams,
        order_ids: order_ids.join(","),
      });
    } else {
      console.log("payload kosong");
    }
  }, [payload]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function getProductNameQty(report) {
    var menu = [
      {
        menu: "A",
        name: "A",
        qty: 0,
      },
      {
        menu: "B",
        name: "B",
        qty: 0,
      },
    ];

    if (report?.length > 0) {
      report?.map((item, index) => {
        item.listProduct.map((product, index) => {
          if (product.menu === "A") {
            menu[0].qty += Number(product.qty);
            menu[0].name = product.product;
          }
          if (product.menu === "B") {
            menu[1].qty += Number(product.qty);
            menu[1].name = product.product;
          }
        });
      });
    }

    return menu;
  }

  function getDeliveryCategoryQty(report) {
    var grouped;
    if (report?.length > 0) {
      grouped = _.groupBy(report, (item) => item.delivery_category);
      // console.log(grouped);
    }

    return grouped;
  }

  const menu = getProductNameQty(report);
  const deliveryCategory = getDeliveryCategoryQty(report);

  function getReportDate(report) {
    if (report?.length > 0) {
      return report[0].deliveryDate;
    } else return "";
  }

  const reportDate = getReportDate(report);
  const strReportDate =
    standardDay(reportDate, "en") + ", " + standardDate(reportDate, "en");
  // console.log("Date: ", strReportDate);

  return (
    <ModalMolecule
      show={showStates["deliveryReport.create"]}
      title="Print Surat Jalan"
      onClose={handleClose}
      size="xl"
    >
      <div ref={componentRef} className="space-y-1 text-sm">
        <div className="flex justify-between items-start p-4 gap-4">
          <div>
            <p className="font-normal whitespace-nowrap">
              {menu[0].menu}: {menu[0].name}{" "}
              <span className="font-semibold">{menu[0].qty} pax</span>
            </p>
            <p className="font-normal whitespace-nowrap">
              {menu[1].menu}: {menu[1].name}{" "}
              <span className="font-semibold">{menu[1].qty} pax</span>
            </p>
          </div>

          <p className="whitespace-nowrap">{strReportDate}</p>
        </div>
        <div className="px-4">
          <Table.Wrapper asIndex={false}>
            <Table.Header>
              <Table.HeaderRow>
                <Table.Heading>No.</Table.Heading>
                <Table.Heading>Customer</Table.Heading>
                <Table.Heading>A</Table.Heading>
                <Table.Heading>B</Table.Heading>
                <Table.Heading>Alamat</Table.Heading>
                <Table.Heading>Note</Table.Heading>
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {report?.length > 0 &&
                report?.map((item, index) => (
                  <Table.BodyRow key={index}>
                    <Table.Cell>
                      <p className="text-xs font-light">{item.order_code}</p>
                    </Table.Cell>
                    <Table.Cell>
                      <p className="leading-tight">
                        {item.customerName}{" "}
                        {item?.nickName ? "(" + item.nickName + ")" : ""}
                      </p>
                      <div className="flex items-center gap-1">
                        <input
                          className="peer print:hidden accent-primary-600"
                          type="checkbox"
                          name={`suratJalan[${index}].showPhone`}
                          id={`suratJalan[${index}].showPhone`}
                          defaultChecked={false}
                        />
                        <p
                          htmlFor={`suratJalan[${index}].showPhone`}
                          className="text-[10px] font-extralight tracking-tighter leading-tight peer-checked:print:block peer-checked:blur-none blur-sm print:hidden"
                        >
                          {item.customerTel}
                        </p>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {item.listProduct
                        .filter((product) => product.menu === "A")
                        .map((product) => +product.qty)
                        .reduce((partialSum, a) => partialSum + a, 0)}
                    </Table.Cell>
                    <Table.Cell>
                      {item.listProduct
                        .filter((product) => product.menu === "B")
                        .map((product) => +product.qty)
                        .reduce((partialSum, a) => partialSum + a, 0)}
                    </Table.Cell>
                    <Table.Cell>
                      <p className="text-xs tracking-tighter leading-tight">
                        {item.customerAddress}
                      </p>
                      <p className="text-[10px] font-extralight text-gray-500 tracking-tighter leading-tight">
                        {item.delivery_category}
                      </p>
                    </Table.Cell>
                    <Table.Cell>
                      <p className="text-[10px] font-light text-gray-500 tracking-tighter leading-tight">
                        {item.note}
                      </p>
                      {item.deliveryTime === 0 ? (
                        <Badge color="green">Kirim Pagi</Badge>
                      ) : (
                        <div></div>
                      )}
                    </Table.Cell>
                  </Table.BodyRow>
                ))}
            </Table.Body>
          </Table.Wrapper>
        </div>
        <div className="w-fit text-gray-700 font-light p-4">
          <h3 className="uppercase text-xs text-gray-900 font-medium pb-1 underline underline-offset-2">
            Rekap Kategori Ongkir
          </h3>
          {report?.length > 0 &&
            Object.entries(deliveryCategory).map(([key, value]) => (
              <div className="flex justify-between items-center gap-2">
                <span className="col-span-3 whitespace-nowrap">{key}:</span>
                <span className="w-4 text-right font-medium">
                  {value.length}
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Rekap Surat Jalan
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default CreateDeliveryReportModal;
