import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import { InputMolecule } from "app/components/molecules/form";
import { SelectMolecule } from "app/components/molecules/select";
import { useFetcher } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useApplicationModals } from "app/common/hooks";
import AppModals from "app/modals";

function DailyOrderReportPage() {
  const today = () => {
    const now = DateTime.now();
    return now.toISODate();
  };
  const [queryParams, setQueryParams] = useState({
    daily_menu: 1,
    delivery_date: today(),
  });

  const [report] = useFetcher({
    modelName: "report",
    methodName: "show",
    payload: { id: queryParams },
  });

  const { openModal, setPayloads } = useApplicationModals();

  function mergeVariantsByName(variants) {
    const result = [];

    variants.forEach((variant) => {
      for (const key in variant) {
        const existingVariant = result.find((item) => item.menu === key);
        if (existingVariant) {
          existingVariant.qty += variant[key];
        } else {
          result.push({ menu: key, qty: variant[key] });
        }
      }
    });

    return result;
  }

  function mergeVariants(variants) {
    console.log(variants);
    var strkey = "";
    var keys = [];
    variants.forEach((variant) => {
      // console.log(variant);
      // console.log(Object.keys(variant).sort());
      // console.log(Object.keys(variant).sort().join(","));
      // console.log(Object.values(variant)[0]);
      strkey = Object.keys(variant).sort().join(", ");
      var menu = {
        name: strkey,
        qty: Object.values(variant)[0],
      };

      // console.log(strkey);
      // if (keys.indexOf(strkey) < 0) {
      //   keys.push(strkey);
      // }
      if (keys.findIndex((x) => x.name === strkey) < 0) {
        keys.push(menu);
      } else {
        keys[keys.findIndex((x) => x.name === strkey)].qty +=
          Object.values(variant)[0];
      }

      console.log(
        keys[keys.findIndex((x) => x.name === strkey)],
        "+",
        Object.values(variant)[0]
      );

      strkey = "";
    });
    console.log(keys);

    return keys;
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <MainLayout
      activeSidebarNavigation="report"
      activeSidebarChild="daily-menu-report"
      breadcrumbItems={[
        { label: "Report", href: "#" },
        { label: "Menu Harian", href: "#" },
      ]}
      pageTitle="Report Menu Harian"
      headingButtons={[
        {
          label: "Print Report",
          type: "button",
          onClick: (e) => {
            setPayloads("dailyMenuReport.view", {
              data: report,
              date: queryParams.delivery_date,
            });
            openModal("dailyMenuReport.view");
          },
          // items: [
          //   {
          //     label: "Download Report",
          //     href: "#",
          //   },
          // ],
        },
      ]}
    >
      <AppModals
        items={["dailyMenuReport.view"]}
        onSuccess={{
          "dailyMenuReport.view": () => fetch(),
        }}
      />
      <div ref={componentRef}>
        <Table.Container>
          <Table.SearchBarWrapper>
            <FormHeader>
              <InputMolecule
                label="Tanggal Kirim"
                type="date"
                value={today()}
                onChange={(e) => {
                  setQueryParams({
                    ...queryParams,
                    delivery_date: e.target.value,
                  });
                }}
              />

              {/* <SelectMolecule
                label="Menu"
                placeholder="Menu"
                options={[
                  {
                    value: "a",
                    label: "A",
                  },
                  {
                    value: "b",
                    label: "B",
                  },
                ]}
              /> */}

              {/* <SelectMolecule
                label="Status Invoice"
                placeholder="Pilih Status"
                options={[
                  {
                    value: "completed",
                    label: "Completed",
                  },
                  {
                    value: "partial",
                    label: "Partial",
                  },
                ]}
              /> */}
            </FormHeader>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
          <IconButton icon={PrinterIcon} />
        </Table.UtilitiesButtonWrapper> */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
            <Table.Wrapper asIndex={false}>
              {report?.daily_order?.length > 0 && (
                <>
                  <Table.Header>
                    <Table.HeaderRow>
                      <Table.Heading>
                        Menu {report?.daily_order[0].categories}
                      </Table.Heading>
                      <Table.Heading align="right">Qty</Table.Heading>
                    </Table.HeaderRow>
                  </Table.Header>
                  <Table.Body>
                    <Table.BodyRow key={0}>
                      <Table.Cell fontWeight="semibold">
                        <p className="pl-2">{report?.daily_order[0].name}</p>
                      </Table.Cell>
                      <Table.Cell fontWeight="semibold" align="right">
                        {report?.daily_order[0].total_order}
                      </Table.Cell>
                    </Table.BodyRow>
                    {mergeVariants(report?.daily_order[0].variants)?.length >
                      0 &&
                      mergeVariants(report?.daily_order[0].variants).map(
                        (list, index) => (
                          <Table.BodyRow key={index}>
                            <Table.Cell fontWeight="light" gradient="500">
                              <div className="pl-8" key={index}>
                                {list.name}
                              </div>
                            </Table.Cell>
                            <Table.Cell
                              fontWeight="light"
                              gradient="500"
                              align="right"
                            >
                              {list.qty}
                            </Table.Cell>
                          </Table.BodyRow>
                        )
                      )}
                  </Table.Body>
                </>
              )}
            </Table.Wrapper>
            <Table.Wrapper asIndex={false}>
              {report?.daily_order?.length > 1 && (
                <>
                  <Table.Header>
                    <Table.HeaderRow>
                      <Table.Heading>
                        Menu {report?.daily_order[1].categories}
                      </Table.Heading>
                      <Table.Heading align="right">Qty</Table.Heading>
                    </Table.HeaderRow>
                  </Table.Header>
                  <Table.Body>
                    <Table.BodyRow key={1}>
                      <Table.Cell fontWeight="semibold">
                        <p className="pl-2">{report?.daily_order[1].name}</p>
                      </Table.Cell>
                      <Table.Cell fontWeight="semibold" align="right">
                        {report?.daily_order[1].total_order}
                      </Table.Cell>
                    </Table.BodyRow>
                    {mergeVariants(report?.daily_order[1].variants)?.length >
                      0 &&
                      mergeVariants(report?.daily_order[1].variants).map(
                        (list, index) => (
                          <Table.BodyRow key={index}>
                            <Table.Cell fontWeight="light" gradient="500">
                              <div className="pl-8" key={index}>
                                {list.name}
                              </div>
                            </Table.Cell>
                            <Table.Cell
                              fontWeight="light"
                              gradient="500"
                              align="right"
                            >
                              {list.qty}
                            </Table.Cell>
                          </Table.BodyRow>
                        )
                      )}
                  </Table.Body>
                </>
              )}
            </Table.Wrapper>
          </div>
        </Table.Container>
      </div>
    </MainLayout>
  );
}

export default DailyOrderReportPage;
