import { useState, useEffect, Fragment } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import { filter as _filter } from "underscore";
import clsx from "clsx";
import { Badge } from "app/components/atoms/badge";

function PlainMultiselect({ placeholder, name, options = [], value = [], onChange = () => {} }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const localOnPush = (value) => {
    let newLocalValue = localValue;
    newLocalValue.push(value);
    setLocalValue(newLocalValue);
    onChange({
      target: {
        value: newLocalValue,
        name,
      },
    });
  };
  const localOnSplice = (value) => {
    let newLocalValue = localValue;
    newLocalValue = newLocalValue.filter(item => item !== value);
    setLocalValue(newLocalValue);
    onChange({
      target: {
        value: newLocalValue,
        name,
      },
    });
  };

  useEffect(() => {
    if (value.toString() !== localValue.toString()) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className="relative ">
      {_filter(options, (option) => localValue.includes(option.value)).map(
        (option, key) => (
          <div
            key={key}
            className="m-0.5 relative inline-block rounded-lg text-xs font-medium py-0.5 pl-3 pr-6 bg-success-800 dark:bg-success-100 text-success-100 dark:text-success-800"
          >
            {option.label}
            <div
              className="absolute top-0 right-0 h-full flex items-center mx-2 cursor-pointer"
              onClick={(e) => {
                localOnSplice(option.value);
              }}
            >
              <XMarkIcon className="h-3 w-3" />
            </div>
          </div>
        )
      )}
      <PlusCircleIcon
        className="cursor-pointer h-5 w-5 text-gray-300 hover:text-gray-400 dark:text-gray-800 dark:hover:text-gray-700"
        onClick={(e) => {
          setShowDropdown(!showDropdown);
        }}
      />
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={showDropdown}
      >
        <div className="fixed z-50 ml-8 max-h-60 w-fit overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {_filter(options, (option) => !localValue.includes(option.value))
            .length === 0 && (
            <div className="relative text-center select-none py-2 px-4 text-gray-600 dark:text-gray-300">
              <span className="block truncate font-light text-xs">
                No item left
              </span>
            </div>
          )}
          {_filter(options, (option) => !localValue.includes(option.value)).map(
            (option, key) => (
              <div
                key={key}
                className="relative cursor-pointer select-none py-2 px-4 text-gray-900 dark:text-white hover:bg-primary-100 hover:text-primary-900 hover:dark:bg-primary-900 hover:dark:text-primary-100"
                onClick={(e) => {
                  localOnPush(option.value);
                }}
              >
                <span className="block truncate font-normal">
                  {option.label}
                </span>
              </div>
            )
          )}
        </div>
      </Transition>
    </div>
  );
}

export default PlainMultiselect;
