import axios from "../axios";
import h from "../helpers";

const variantItem = {
  store: async (variant_category_id, name, price) => {
    const { data } = await axios.post(
      h.baseUrl('variant'),
      { variant_category_id, name, price },
    );
    return data;
  },
  update: async (id, variant_category_id, name, price) => {
    const { data } = await axios.put(
      h.baseUrl('variant/' + id),
      { variant_category_id, name, price },
    );
    return data;
  },
};

export default variantItem;
