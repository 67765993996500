import { useApplicationModals } from "app/common/hooks";
import { useCreateForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { InputLabel } from "app/components/atoms/form";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import { ClosableCard } from "app/components/atoms/card";

function CreateVariantModal({ onSuccess = () => {} }) {
  const { showStates, closeModal } = useApplicationModals();

  const handleClose = () => closeModal("variant.create");
  const handleSuccess = () => {
    closeModal("variant.create");
    onSuccess();
  };

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      name: "",
      description: "",
      variantCategories: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama harus diisi"),
      description: Yup.string(),
      variantCategories: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Nama Kategori harus diisi"),
          isMultiple: Yup.boolean(),
          variantItems: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Nama Item harus diisi"),
              price: Yup.number().required("Harga Item harus diisi"),
            })
          ),
        })
      ),
    }),
    onSubmit: async (values) => {
      await api.variant.store(
        values.name,
        values.description,
        values.variantCategories.map((variantCategory) => ({
          name: variantCategory.name,
          is_multiple: variantCategory.isMultiple,
          Variants: variantCategory.variantItems.map((variantItem) => ({
            name: variantItem.name,
            price: variantItem.price,
          })),
        }))
      );
    },
    onSuccess: handleSuccess,
  });

  return (
    <ModalMolecule
      show={showStates["variant.create"]}
      title="Tambah Kategori Menu"
      onClose={handleClose}
    >
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <InputMolecule
              label="Nama"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessage={errors.name && touched.name && errors.name}
            />
            <InputMolecule
              label="Deskripsi"
              type="text"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              errorMessage={
                errors.description && touched.description && errors.description
              }
            />
            <div>
              <hr className="my-5" />
            </div>
            <div>
              <InputLabel>Kategori Menu</InputLabel>
              <FieldArray name="variantCategories">
                {(arrayHelpers) => (
                  <div className="space-y-4 mt-2">
                    {values.variantCategories.map(
                      (variantCategory, variantCategoryKey) => (
                        <ClosableCard
                          key={variantCategoryKey}
                          onClose={() =>
                            arrayHelpers.remove(variantCategoryKey)
                          }
                        >
                          <div className="space-y-2 p-2">
                            <InputMolecule
                              label="Nama Kategori"
                              type="text"
                              name={`variantCategories[${variantCategoryKey}].name`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={variantCategory.name}
                              errorMessage={
                                getIn(
                                  errors,
                                  `variantCategories[${variantCategoryKey}].name`
                                ) &&
                                getIn(
                                  touched,
                                  `variantCategories[${variantCategoryKey}].name`
                                ) &&
                                getIn(
                                  errors,
                                  `variantCategories[${variantCategoryKey}].name`
                                )
                              }
                            />
                            <div className="space-x-2">
                              <input
                                type="checkbox"
                                name={`variantCategories[${variantCategoryKey}].isMultiple`}
                                checked={variantCategory.isMultiple}
                                onChange={handleChange}
                              />
                              <span>Boleh pilih lebih dari 1</span>
                            </div>
                            <div>
                              <hr className="my-3" />
                            </div>
                            <div>
                              {/* <InputLabel>Item Varian</InputLabel> */}
                              <FieldArray
                                name={`variantCategories[${variantCategoryKey}].variantItems`}
                              >
                                {(arrayHelpers) => (
                                  <div className=" ">
                                    {variantCategory.variantItems.map(
                                      (variantItem, variantItemKey) => (
                                        <ClosableCard
                                          key={variantItemKey}
                                          onClose={() =>
                                            arrayHelpers.remove(variantItemKey)
                                          }
                                          withBorder={false}
                                        >
                                          <div className="grid grid-cols-2 gap-2 items-start">
                                            <InputMolecule
                                              label="Nama Item"
                                              type="text"
                                              name={`variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].name`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={variantItem.name}
                                              errorMessage={
                                                getIn(
                                                  errors,
                                                  `variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].name`
                                                ) &&
                                                getIn(
                                                  touched,
                                                  `variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].name`
                                                ) &&
                                                getIn(
                                                  errors,
                                                  `variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].name`
                                                )
                                              }
                                            />
                                            <InputMolecule
                                              label="Harga"
                                              type="number"
                                              name={`variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].price`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={variantItem.price}
                                              errorMessage={
                                                getIn(
                                                  errors,
                                                  `variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].price`
                                                ) &&
                                                getIn(
                                                  touched,
                                                  `variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].price`
                                                ) &&
                                                getIn(
                                                  errors,
                                                  `variantCategories[${variantCategoryKey}].variantItems[${variantItemKey}].price`
                                                )
                                              }
                                            />
                                          </div>
                                        </ClosableCard>
                                      )
                                    )}
                                    <div>
                                      <div className="mt-2">
                                        <DottedOutlineButton
                                          type="button"
                                          onClick={(e) =>
                                            arrayHelpers.push({
                                              name: "",
                                              price: 0,
                                            })
                                          }
                                        >
                                          Tambah Item
                                        </DottedOutlineButton>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        </ClosableCard>
                      )
                    )}
                    <div>
                      <div className="my-8">
                        <DottedOutlineButton
                          type="button"
                          onClick={(e) =>
                            arrayHelpers.push({
                              name: "",
                              isMultiple: false,
                              variantItems: [],
                            })
                          }
                        >
                          Tambah Kategori
                        </DottedOutlineButton>
                      </div>
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
            {/* <div>
              <hr className="my-5" />
            </div> */}
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Simpan</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateVariantModal;
